/* 
    maneja todas las funciones del store, peticiones referentes a pacientes, profesionales, cuentas, usuarios
*/

import React, { useReducer } from "react";
import { useToast } from "@chakra-ui/react";
import Cookies from "js-cookie";
import StoreReducer from "./StoreReducer";
import { StoreContext } from "./StoreContext";
import { lanekApi } from "../../api/lanekApi";
import { useAuth } from "../../hooks/useAuth";

export const StoreState = ({ children }) => {
  const { logoutRequest, usuario } = useAuth();
  const toast = useToast();

  let user_photo_url;
  if (Cookies.get("user-photo")) {
    user_photo_url = JSON.parse(Cookies.get("user-photo"));
  }

  const initialState = {
    pacientes: [],
    cuentas: [],
    professionals: [],
    feedbacks: [],
    feedback: {},
    paciente: "",
    summary: {},
    organizacion: {},
    profesional: {},
    user_photo: user_photo_url || null,
    isLoading: false,
  };

  const [state, dispatch] = useReducer(StoreReducer, initialState);

  /* *************************************** 
    METODOS DE PACIENTES
  ****************************************** */

  const getsPatients = async () => {
    const url = `/paciente`;
    try {
      const { data } = await lanekApi.get(url);

      dispatch({
        type: "CARGAR_PACIENTES",
        payload: data.items,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getsPatientsByOrganization = async (idorganizacion) => {
    const url = `/organizacion/${idorganizacion}/pacientes`;
    try {
      const { data } = await lanekApi.get(url);
      const usuarios = data.pacientes;

      dispatch({
        type: "CARGAR_PACIENTES",
        payload: usuarios,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getPatient = async (idpaciente) => {
    const url = `paciente/${idpaciente}`;
    try {
      const { data } = await lanekApi.get(url);
      dispatch({
        type: "CARGAR_PACIENTE",
        payload: data.item,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const createPatient = async (data) => {
    const url = "/paciente/nuevo-paciente";
    const token = localStorage.getItem("token");

    try {
      await lanekApi.post(
        url,
        {
          ...data,
          id_profesional: usuario.id_profesional,
          id_organizacion: usuario.id_organizacion,
        },
        { headers: { Authorization: token } }
      );
      await getsPatientsByOrganization(usuario.id_organizacion);

      toast({
        description: "Paciente creado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      logoutRequest();
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const updatePatient = async (data) => {
    const { id: idpaciente } = data;
    const url = `/paciente/${idpaciente}`;
    try {
      await lanekApi.put(url, { ...data });
      await getPatient(idpaciente);
      toast({
        description: "Paciente actualizado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  /* *************************************** 
    METODOS DE CUENTAS
  ****************************************** */

  const getsOrganizations = async () => {
    const url = "/organizacion";
    try {
      const { data } = await lanekApi.get(url);

      dispatch({
        type: "CARGAR_CUENTAS",
        payload: data.organizaciones,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getOrganization = async (idorganizacion) => {
    const url = `/organizacion/${idorganizacion}`;
    try {
      const { data } = await lanekApi.get(url);
      dispatch({
        type: "SET_ORGANIZACION",
        payload: data.organizacion,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const createOrganization = async (data) => {
    const url = "/organizacion/nuevo-cliente";
    try {
      await lanekApi.post(url, data);
      await getsOrganizations();

      toast({
        description: "Organización creada exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const updateOrganization = async (data) => {
    const { id: idcuenta } = data;
    const url = `/organizacion/editar-cliente/${idcuenta}`;
    try {
      await lanekApi.put(url, data);
      await getOrganization(idcuenta);
      await getsOrganizations();
      toast({
        description: "Información actualizada.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  /* *************************************** 
    METODOS DE PROFESIONALES
  ****************************************** */

  const getsProfessionals = async () => {
    const url = "/profesional";
    try {
      const { data } = await lanekApi.get(url);
      const usuarios = data.profesionales;

      dispatch({
        type: "CARGA_PROFESIONALES",
        payload: usuarios,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getsProsByOrganization = async (idorganizacion) => {
    const url = `/organizacion/${idorganizacion}/profesionales`;
    try {
      const { data } = await lanekApi.get(url);
      const usuarios = data.profesionales;

      dispatch({
        type: "CARGA_PROFESIONALES",
        payload: usuarios,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getProfessional = async (idprofesional) => {
    const url = `/profesional/${idprofesional}`;
    try {
      const { data } = await lanekApi.get(url);
      await getUserPhoto(idprofesional);

      dispatch({
        type: "SET_PROFESIONAL",
        payload: data,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getSummariesPro = async (id) => {
    const urlExams = `/resume/exams/professional/${id}`;
    const urlPatients = `/resume/patients/professional/${id}`;

    try {
      const { data: dataExams } = await lanekApi.get(urlExams);
      const { data: dataPatients } = await lanekApi.get(urlPatients);

      const data = { dataExams, dataPatients };

      dispatch({
        type: "GET_SUMMARIES_DATA",
        payload: data,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getUserPhoto = async (id) => {
    const url = `/profesional/user-photo/${id}`;
    try {
      const { data } = await lanekApi.get(url);
      Cookies.set("user-photo", JSON.stringify(data.image_url), {
        expires: 1 / 96,
        secure: true,
      });

      dispatch({
        type: "GET_USER_PHOTO",
        payload: data.image_url,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const createProfessional = async (data) => {
    const idOrganizacion = usuario.id_organizacion;
    const url = "/profesional/nuevo-profesional";
    const dataUsuario = { ...data, id_organizacion: idOrganizacion };
    try {
      await lanekApi.post(url, dataUsuario);
      await getsProsByOrganization(idOrganizacion);

      toast({
        description: "Usuario creado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const updateProfessional = async (data) => {
    const { id, id_organizacion } = data;
    const url = `/profesional/editar-profesional/${id}`;

    try {
      await lanekApi.put(url, { ...data });
      await getProfessional(id);
      await getsProsByOrganization(id_organizacion);

      toast({
        description: "Usuario actualizado.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  /* *************************************** 
    METODOS DE FEEDBACKS
  ****************************************** */

  const getFeedbacks = async () => {
    const url = "/feedback/";
    try {
      const response = await lanekApi.get(url);
      const feedbacks = response.data.items;
      const feedbacks_sorted = feedbacks.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      dispatch({
        type: "CARGA_FEEDBACKS",
        payload: feedbacks_sorted,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getFeedback = async (idfeedback) => {
    const url = `/feedback/${idfeedback}`;
    try {
      const response = await lanekApi.get(url);
      const imageUrl = await lanekApi.get(
        `/feedback/image/${response.data.item.id}`
      );

      const data = {
        ...response.data.item,
        url: imageUrl.data.url,
      };

      dispatch({
        type: "GET_FEEDBACK",
        payload: data,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const updateFeedback = async (idfeedback) => {
    const url = `/feedback/${idfeedback}`;
    try {
      await lanekApi.put(url, { estado: "Finalizado" });
      await getFeedbacks();

      toast({
        description: "Feedback actualizado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const createFeedback = async (image, data) => {
    const url = "/feedback";

    const autor = usuario.profesional;
    const nombre_autor = autor.apellidos
      ? autor.nombre + " " + autor.apellidos
      : autor.nombre;

    const contentEmail = { ...data, autor: nombre_autor, email: autor.email };

    const formData = new FormData();
    formData.append("feedback", JSON.stringify(contentEmail));
    formData.append("image", image);
    try {
      await lanekApi.post(url, formData);

      toast({
        description: "Feedback creado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        description: error.response.data.message,
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };
  return (
    <StoreContext.Provider
      value={{
        ...state,
        createFeedback,
        createOrganization,
        createPatient,
        createProfessional,
        getFeedback,
        getFeedbacks,
        getOrganization,
        getPatient,
        getProfessional,
        getsOrganizations,
        getsPatients,
        getsPatientsByOrganization,
        getsProfessionals,
        getsProsByOrganization,
        getSummariesPro,
        getUserPhoto,
        updateFeedback,
        updateOrganization,
        updatePatient,
        updateProfessional,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

import React from "react";
import { Box, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import {
  FaMicrophone,
  FaMicrophoneSlash,
  FaPlay,
  FaStop,
  FaVideo,
  FaVideoSlash,
} from "react-icons/fa6";

export const VideoCreatorOverlay = ({
  isAudioEnabled,
  isRecording,
  isVideoEnabled,
  startRecording,
  stopRecording,
  timer,
  toggleAudio,
  toggleVideo,
}) => {
  return (
    <>
      <Box position="absolute" bottom={2}>
        {isRecording ? (
          <Tooltip hasArrow label="Detener grabación" placement="top">
            <IconButton
              borderRadius="full"
              colorScheme="red"
              icon={<FaStop />}
              mr={2}
              onClick={stopRecording}
            />
          </Tooltip>
        ) : (
          <Tooltip hasArrow label="Iniciar grabación" placement="top">
            <IconButton
              borderRadius="full"
              colorScheme="green"
              icon={<FaPlay />}
              mr={2}
              onClick={startRecording}
            />
          </Tooltip>
        )}

        {!isVideoEnabled ? (
          <Tooltip hasArrow label="Desactivar cámara" placement="top">
            <IconButton
              borderRadius="full"
              borderWidth={1}
              borderColor="white"
              colorScheme="transparent"
              icon={<FaVideo />}
              mr={2}
              onClick={toggleVideo}
            />
          </Tooltip>
        ) : (
          <Tooltip hasArrow label="Activar cámara" placement="top">
            <IconButton
              borderRadius="full"
              colorScheme="red"
              icon={<FaVideoSlash />}
              mr={2}
              onClick={toggleVideo}
            />
          </Tooltip>
        )}

        {!isAudioEnabled ? (
          <Tooltip hasArrow label="Desactivar micrófono" placement="top">
            <IconButton
              borderRadius="full"
              borderWidth={1}
              borderColor="white"
              colorScheme="transparent"
              icon={<FaMicrophone />}
              onClick={toggleAudio}
            />
          </Tooltip>
        ) : (
          <Tooltip hasArrow label="Activar micrófono" placement="top">
            <IconButton
              borderRadius="full"
              colorScheme="red"
              icon={<FaMicrophoneSlash />}
              onClick={toggleAudio}
            />
          </Tooltip>
        )}
      </Box>

      <Box
        borderColor="white"
        borderRadius="full"
        borderWidth={1}
        left={2}
        position="absolute"
        top={2}
      >
        <Flex align={"center"} h={14} justify={"center"} padding={2} w={14}>
          {timer.minutes.toString().padStart(2, "0")}:
          {timer.seconds.toString().padStart(2, "0")}
        </Flex>
      </Box>
    </>
  );
};

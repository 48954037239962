import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";
import { styles } from "../../../utils/stylesDocumentPdf";

const ReportResults = ({ table }) =>
  Object.entries(table).map((t) => {
    const capitalizeFirst = (str) => {
      if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      } else {
        return null;
      }
    };

    return (
      t[1]?.title && (
        <View wrap={false}>
          {Object.keys(t[1]?.stats) === undefined ? (
            <>
            {Object.keys(t[1]?.url).length > 5 ? (
              <>
            <View style={styles.tableFeatures} wrap={false}>
                {/* Fila */}
                <View style={styles.row}>
                  {/* Primera Columna */}
                  <View style={styles.tableCol70}>
                    <Text style={styles.oneTitleGraphic}>
                      {capitalizeFirst(t[1]?.title)}
                    </Text>
                    <Image
                      src={{
                        uri: t[1]?.url,
                        method: "GET",
                        headers: { "Cache-Control": "no-cache" },
                        body: "",
                      }}
                    />
                    <Text style={styles.oneSubTitleGraphic}>
                      {capitalizeFirst(t[1]?.description)}
                    </Text>
                  </View>

                  {/* Segunda Columna */}
                  <>
                    <View style={styles.cell}>
                      {Object.entries(t[1]?.stats).length
                        ? // FILA 1: Si stats tiene 10 datos la fila tendra 5 celdas
                          Object.entries(t[1]?.stats).map((m) => (
                            <View style={styles.cell2}>
                              <Text style={styles.oneSubTitleCell}>
                                {capitalizeFirst(m[0])}
                              </Text>
                            </View>
                          ))
                        : null}
                    </View>
                  </>

                  <View style={styles.cell3}>
                    {Object.entries(t[1]?.stats).length
                      ? Object.entries(t[1]?.stats).map((m) => (
                          <View style={styles.cell4}>
                            <Text style={styles.oneSubTitleCell}>
                              {Number(m[1]).toFixed(2)}
                            </Text>
                          </View>
                        ))
                      : null}
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.tableCol100}>
                    <Text style={styles.titleNote}>NOTAS:</Text>
                  </View>
                </View>
              </View>
              <Text style={styles.margenGraphic}></Text> </> ) : null}
              
            </>
          ) : (
            <>
              <View style={styles.tableFeatures} wrap={false}>
                {/* Fila */}                
                <View style={styles.row}>
                  {/* Primera Columna */}
                  <View style={styles.tableCol70}>
                    <Text style={styles.oneTitleGraphic}>{t[1]?.title}</Text>
                    <Image
                      src={{
                        uri: t[1]?.url,
                        method: "GET",
                        headers: { "Cache-Control": "no-cache" },
                        body: "",
                      }}
                    />
                    <Text style={styles.oneSubTitleGraphic}>
                      {t[1]?.description}
                    </Text>
                  </View>

                  <>
                    <View style={styles.cell5}>
                      <Text style={styles.oneSubTitleCell}>SIN DATOS</Text>
                    </View>
                  </>
                </View>
                <View style={styles.row}>
                  <View style={styles.tableCol100}>
                    <Text style={styles.titleNote}>NOTAS:</Text>
                  </View>
                </View>
              </View>
              <Text style={styles.margenGraphic}></Text>
            </>
          )}
        </View>
      )
    );
  });

export default ReportResults;

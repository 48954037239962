import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPenToSquare } from "react-icons/fa6";
import { useStore } from "../../hooks/useStore";
import { PacienteForm } from "../Forms/PacienteForm";
import { formatearRut } from "../../utils/formatearRut";

export const EditarPaciente = ({ patient }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updatePatient } = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [rut, setRut] = useState(patient.rut);

  const handleRutChange = (event) => {
    const formattedRut = formatearRut(event.target.value);
    setRut(formattedRut);
  };

  const handleUpdate = async (values, { setSubmitting }) => {
    const data = { ...values, rut };
    setIsLoading(true);
    await updatePatient(data);
    setSubmitting(false);
    setIsLoading(false);
    onClose();
  };

  return (
    <>
    <Button
            leftIcon={<FaPenToSquare />}
            colorScheme="twitter"
            size={{ base: "sm", md: "md" }}
            borderRadius="md"
            onClick={onOpen}
          >
            Editar
          </Button>
      {/* <ButtonWithIcon fn={onOpen} titleIcon={FaEdit} titleButton={"Editar"} /> */}

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editando organización</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PacienteForm
              handleRutChange={handleRutChange}
              initialValues={patient}
              isLoading={isLoading}
              onSubmit={handleUpdate}
              rut={rut}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Heading, ScaleFade, Flex, Text } from "@chakra-ui/react";
import { TablaExamenes } from "../components/Tablas/TablaExamenes";
import { DetalleExamen } from "../components/visuales/DetalleExamen";
import { RenderLoading } from "../components/RenderAlert";
import { useStore } from "../hooks/useStore";
import { EditarPaciente } from "../components/Ui";

const PatientScreen = () => {
  const { idpaciente } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [openDetalleExamen, setOpenDetalleExamen] = useState(false);
  const { getPatient, paciente } = useStore();

  sessionStorage.setItem("paciente", idpaciente);

  const handleOpenDetalle = (idexam) => {
    sessionStorage.setItem("examen", idexam);
    setOpenDetalleExamen(!openDetalleExamen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await getPatient(idpaciente);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [idpaciente]);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            maxW={"100%"}
            paddingLeft={{ base: 4, xl: 0 }}
            paddingRight={4}
          >
            <Flex
              flex={1}
              direction={"column"}
              bg={"#fff"}
              borderRadius={"16px"}
              boxShadow="md"
              padding={4}
            >
              <Flex justify="space-between" align={"center"}>
                <Heading textTransform="capitalize" fontSize="2xl">
                  {`${paciente.nombre} ${paciente.apellidos}`}
                </Heading>
                <EditarPaciente patient={paciente} />
              </Flex>

              <Text mb={1}>
                <strong>RUT: </strong>
                {paciente.rut || "NA"}
              </Text>
              <Text mb={1}>
                <strong>Correo Electrónico: </strong> {paciente.email || "NA"}
              </Text>
              <Text mb={1}>
                <strong>Previsión: </strong> {paciente.prevision || "NA"}
              </Text>
              <Text mb={1}>
                <strong>Teléfono: </strong> {paciente.telefono || "NA"}
              </Text>
              <Text mb={1}>
                <strong>Dirección: </strong> {paciente.direccion || "NA"}
              </Text>
              <Text mb={1}>
                <strong>Fecha De Nacimiento: </strong>
                {paciente.fecha_nacimiento || "NA"}
              </Text>
              <Text mb={1}>
                <strong>Género: </strong> {paciente.sexo || "NA"}
              </Text>
              <Text>
                <strong>Estado Civil: </strong> {paciente.estado_civil || "NA"}
              </Text>
            </Flex>

            {openDetalleExamen ? (
              <DetalleExamen setOpenDetalle={handleOpenDetalle} />
            ) : (
              <TablaExamenes
                idpaciente={idpaciente}
                paciente={paciente}
                setOpenDetalle={handleOpenDetalle}
              />
            )}
          </Container>
        </ScaleFade>
      )}
    </>
  );
};

export default PatientScreen;

import React from "react";
import "../../../src/assets/css/App.css";
import { Box, Flex, Image } from "@chakra-ui/react";

export const ImagesAbma = ({ dataExamType, imagenes }) => {
    
  if (dataExamType === "abma-lite") {
    return (
      <>
        {imagenes.map((image, index) => (            
          <Flex
            borderRadius="md"
            boxShadow="md"
            height="object-fit"
            justify="center"
            mb={4}
            p={{ base: 0, md: 6 }}
            w="full"
          >
            {console.log("dentro del map", image.url)}
            <Box justifySelf="center">
              <Image
                alt="Imagenes"
                objectFit="contain"
                boxSize={"xs"}
                key={image.key}
                mt={4}
                src={image.url}
                width="100%"
              />
            </Box>
          </Flex>
        ))}
      </>
    );
  }
};

import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { FaFileExcel } from "react-icons/fa";
import { ButtonWithIcon } from "../components/CustomButtons";

export const ExportToExcel = ({ data }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let examenes = [];
  let dataJson = [];
  let dataJson2 = [];
  let dataExamenJson = [];

  // Se se separan los arrays de objetos
  examenes.push(data.examenes);
  dataJson.push(data.jsonDataArray2);

  const generateExcelData = () => {
    if (!examenes) {
      return [];
    }

    // Se itera el array de objetos examenes
    if (examenes.length) {
      Object.entries(examenes).map((t) =>
        Object.entries(t[1]).map((m) => dataExamenJson.push(m[1]))
      );
    }

    // Se itera el array de objetos recursos
    if (dataJson.length) {
      Object.entries(dataJson).map((t) =>
        Object.entries(t[1]).map((m) => dataJson2.push(m[1]?.data.resources))
      );
    }

    // Se incrusta recursos en examenes
    if (dataExamenJson.length) {
      dataExamenJson.forEach((item, index) => {
        item.arrayInterno = dataJson2[index];
      });
    }

    const excludedKeys = [
      //   "id",
      "id_lugarAtencion",
      "Voices",
      "SPECTROGRAM",
      "HRF",
      "arrayInterno",
      "resources_name",
      "stats",
      "title",
      "descripcion",
      "url",
    ];

    return dataExamenJson.map((item) => {
      const examenes = {};
      for (const key in item) {
        if (!excludedKeys.includes(key)) {
          examenes[key] = item[key];
          if (item?.paciente !== undefined) {
            for (const key2 in item.paciente) {
              if (!excludedKeys.includes(key2)) {
                examenes[key2] = item.paciente[key2];
                for (const key3 in item.arrayInterno.F0) {
                  if (!excludedKeys.includes(key3)) {
                    let arrayF0 = [];
                    arrayF0.push(item.arrayInterno.F0[key3].join(", "));
                    examenes["F0"] = arrayF0.join(", ");
                  }
                }
                // for (const key3 in item.arrayInterno.SPL) {
                //     if (!excludedKeys.includes(key3)) {
                //         // console.log("cantidad", item.arrayInterno.SPL[key3].length)
                //       const midIndex = Math.floor(item.arrayInterno.SPL[key3].length / 2);
                //       const firstPart = item.arrayInterno.SPL[key3].slice(0, midIndex);
                //       const secondPart = item.arrayInterno.SPL[key3].slice(midIndex);
                //     //   const threePart = item.arrayInterno.SPL[key3].slice(midIndex);
                //     //   const fourPart = item.arrayInterno.SPL[key3].slice(midIndex);
                //       examenes["firstPartSPL"] = firstPart.join(', ');
                //       examenes["secondPartSPL"] = secondPart.join(', ');
                //     //   examenes["threePartPL"] = threePart.join(', ');
                //     //   examenes["fourPartSPL"] = fourPart.join(', ');
                //     }
                //   }
                for (const key3 in item.arrayInterno.H1H2) {
                  if (!excludedKeys.includes(key3)) {
                    // let arrayH1H2 = [];
                    const midIndex = Math.floor(
                      item.arrayInterno.H1H2[key3].length / 2
                    );
                    const firstPart = item.arrayInterno.H1H2[key3].slice(
                      0,
                      midIndex
                    );
                    const secondPart =
                      item.arrayInterno.H1H2[key3].slice(midIndex);
                    examenes["firstPartH1H2"] = firstPart.join(", ");
                    examenes["secondPartH1H2"] = secondPart.join(", ");
                    //   arrayH1H2.push(item.arrayInterno.H1H2[key3].join(', '))
                    //   examenes["H1H2"] = arrayH1H2.join(", ");
                  }
                }
                for (const key3 in item.arrayInterno.JITTER) {
                  if (!excludedKeys.includes(key3)) {
                    let arrayJITTER = [];
                    arrayJITTER.push(item.arrayInterno.JITTER[key3].join(", "));
                    examenes["JITTER"] = arrayJITTER.join(", ");
                  }
                }
                for (const key3 in item.arrayInterno.SHIMMER) {
                  if (!excludedKeys.includes(key3)) {
                    let arraySHIMMER = [];
                    arraySHIMMER.push(
                      item.arrayInterno.SHIMMER[key3].join(", ")
                    );
                    examenes["SHIMMER"] = arraySHIMMER.join(", ");
                  }
                }
              }
            }
          }
        }
      }
      return examenes;
    });
  };

  const exportToExcel = () => {
    if (examenes) {
      const fileName = "Resultados examenes";
      const excelData = generateExcelData();
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { examenes: ws }, SheetNames: ["examenes"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const examenes = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(examenes, fileName + fileExtension);
    }
  };

  return (
    <ButtonWithIcon
      fn={exportToExcel}
      titleIcon={FaFileExcel}
      titleButton="Exportar Excel"
    >
      Excel
    </ButtonWithIcon>
  );
};

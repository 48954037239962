/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Input, ScaleFade } from "@chakra-ui/react";
import { TablaCuentas } from "../components/Tablas/TablaCuentas";
import { StoreContext } from "../context/store/StoreContext";
import { RenderLoading } from "../components/RenderAlert";
import { CrearCuenta } from "../components/Ui";

const OrganizationScreen = () => {
  const { getsOrganizations, cuentas } = useContext(StoreContext);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const cuentasFiltradas = () => {
    if (search.length === 0) return cuentas;

    // Si hay algo en la barra de busqueda
    const filtrados = cuentas.filter((cuenta) => {
      return (
        cuenta.nombre_cuenta.toLowerCase().includes(search.toLowerCase()) ||
        cuenta.rut.toString().includes(search)
      );
    });
    return filtrados;
  };

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
  };

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        setIsLoading(true);
        await getsOrganizations();
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAccounts();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            bg={"white"}
            shadow={"md"}
            rounded={"md"}
            maxW={"full"}
            py={8}
          >
            <Box display="flex" justifyContent="center" mb={4}>
              <Input
                boxShadow="md"
                htmlSize={40}
                size="md"
                width="auto"
                variant="filled"
                value={search}
                onChange={onSearchChange}
                placeholder="Buscar cuenta por nombre o rut"
              />

              <CrearCuenta />
            </Box>
            <TablaCuentas cuentas={cuentasFiltradas()} />
          </Container>
        </ScaleFade>
      )}
    </>
  );
};

export default OrganizationScreen;

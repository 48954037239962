import React from "react";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { formatearFecha } from "../../helpers/invertirFecha";
import { EditarCuenta } from "../Ui";

export const TablaCuentas = ({ cuentas }) => {
  return (
    <>
      {cuentas.length > 0 ? (
        <TableContainer borderRadius={10} shadow={"md"}>
          <Table variant="simple" bg={"white"}>
            <Thead bg="cyan.400">
              <Tr>
                <Th>cuenta</Th>
                <Th>fecha de pago</Th>
                <Th>estado</Th>
                <Th>acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {cuentas.map((cuenta) => (
                <Tr key={cuenta.id}>
                  <Td>
                    <VStack align="stretch">
                      <Text>{cuenta.nombre + " / " + cuenta.rut}</Text>
                      <Text>{cuenta.email}</Text>
                    </VStack>
                  </Td>
                  <Td>{formatearFecha(cuenta.fecha_pago)}</Td>
                  <Td>
                    {cuenta.is_habilitado ? "Habilitado" : "Deshabilitado"}
                  </Td>
                  <Td>
                    <EditarCuenta cuenta={cuenta} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <h2>Sin resultados, intente nuevamente.</h2>
      )}
    </>
  );
};

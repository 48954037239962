/* 
este componente es publico del login, si el usuario no esta autenticado lo mantiene en el login, si esta autenticado lo redirige al home page 
*/

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const PublicRouter = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Navigate to="/inicio" /> : <Outlet />;
};

export default PublicRouter;

import React from "react";
import { Page, Document } from "@react-pdf/renderer";
import ReporteAG from "./ReporteAG";
import ReportResults from "./ReportResults";
import ReportFooter from "./ReportFooter";
import { styles } from "../../../utils/stylesDocumentPdf";
import InfoData from "../../../jsonData/InfoData";
import HeaderReport from "./HeaderReport";

const PdfDocumentAVM = ({ data, images, paciente }) => {
  let resource_name;
  let resource_name_split;
  let resource_array = [];

  if (data) {
    resource_array = [{ ...data }];
    images.forEach((t) => {
      resource_name = t.key.substr(45);
      resource_name_split = resource_name.split(".")[0];
      Object.entries(resource_array).forEach((m) => {
        Object.entries(m[1].resources).forEach((l) => {
          if (l[1]?.resources_name) {
            if (
              l[1]?.resources_name === "spl" ||
              l[1]?.resources_name === "f0"
            ) {
              let imagen = l[1]?.resources_name + "_hist";
              if (resource_name_split === imagen) {
                l[1].url = t.url;
              }
            }
            if (
              resource_name_split === l[1].resources_name &&
              l[1]?.resources_name !== "spl" &&
              l[1]?.resources_name !== "f0"
            ) {
              l[1].url = t.url;
            }
          }
        });
      });
    });
  }
  return (
    <Document>
      <Page style={styles.body} key={paciente.id}>
        <HeaderReport info={InfoData} />
        <ReporteAG info={InfoData} pacienteData={paciente} data={data} />
        {Object.entries(resource_array).map((t) => (
          <ReportResults key={t.descripcion} table={t[1].resources} />
        ))}
        <ReportFooter info={InfoData} />
      </Page>
    </Document>
  );
};

export default PdfDocumentAVM;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaAngleLeft, FaAngleRight, FaIdCard } from "react-icons/fa6";

const TablaPaciente = ({ patients }) => {
  const navigate = useNavigate();

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = patients?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(patients?.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPagination = () => {
    const adjacentPages = 2;
    const totalAdjacentPages = adjacentPages * 2 + 1;

    let startPage = Math.max(1, currentPage - adjacentPages);
    let endPage = Math.min(totalPages, startPage + totalAdjacentPages - 1);

    if (endPage - startPage < totalAdjacentPages - 1) {
      startPage = Math.max(1, endPage - totalAdjacentPages + 1);
    }

    return (
      <>
        <IconButton
          aria-label="pagina anterior"
          borderRadius="full"
          colorScheme="twitter"
          icon={<Icon as={FaAngleLeft} />}
          isDisabled={currentPage === 1}
          size={{ base: "sm", md: "md" }}
          variant="ghost"
          onClick={() =>
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
          }
        />

        {pageNumbers.slice(startPage - 1, endPage).map((pageNumber) => (
          <Button
            borderRadius="full"
            colorScheme="twitter"
            key={pageNumber}
            mx={1}
            size={{ base: "sm", md: "md" }}
            variant={pageNumber === currentPage ? "solid" : "outline"}
            onClick={() => goToPage(pageNumber)}
            style={{
              fontWeight: pageNumber === currentPage ? "bold" : "normal",
            }}
          >
            {pageNumber}
          </Button>
        ))}

        <IconButton
          aria-label="pagina siguiente"
          borderRadius="full"
          colorScheme="twitter"
          icon={<Icon as={FaAngleRight} />}
          isDisabled={currentPage === totalPages}
          size={{ base: "sm", md: "md" }}
          variant="ghost"
          onClick={() =>
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
          }
        />
      </>
    );
  };

  // Cambiar a una página específica
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(e.target.value, 10));
  };

  const handleShowPatient = (id) => {
    navigate(`/pacientes/${id}`);
  };
  return (
    <>
      <Flex align="center" mb={4}>
        <Select
          onChange={handleItemsPerPageChange}
          value={itemsPerPage}
          bg="white"
          boxShadow={"md"}
          width="80px"
          mr={2}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </Select>
        <Text>Elementos por página</Text>
      </Flex>

      {currentItems?.length > 0 && (
        <TableContainer borderRadius={10} boxShadow="md" bg="white" maxW='100%'>
          <Table variant="simple">
            <TableCaption>
              <Flex justify="space-between" align="center">
                <Box>{renderPagination()}</Box>
              </Flex>
            </TableCaption>
            <Thead bg="cyan.400">
              <Tr>
                <Th>Paciente</Th>
                <Th>RUT</Th>
                <Th>Ficha</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map(({ id, nombre, apellidos, rut }) => (
                <Tr key={id}>
                  <Td>{`${nombre} ${apellidos}`}</Td>
                  <Td>{rut}</Td>
                  <Td>
                    <Button
                      key={id}
                      leftIcon={<FaIdCard />}
                      colorScheme="twitter"
                      variant="solid"
                      size={{ base: "sm", md: "md" }}
                      onClick={() => handleShowPatient(id)}
                    >
                      Ficha
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default TablaPaciente;

import React, { useRef, useState } from "react";
import {
  Avatar,
  AvatarBadge,
  Button,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FaPenToSquare } from "react-icons/fa6";
import { lanekApi } from "../../api/lanekApi";
import { useStore } from "../../hooks/useStore";

const ProfileUserAvatar = ({ usuario, profesional }) => {
  const fileInputRef = useRef(null);

  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getUserPhoto, user_photo } = useStore();
  const { item } = profesional;

  const fullname = item?.apellidos
    ? `${item?.nombre} ${item?.apellidos}`
    : `${item?.nombre}`;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(file);
      setHasChanges(true);
    } else {
      setHasChanges(false);
      setPreviewURL(null);
    }
  };

  const confirmUpload = async () => {
    try {
      if (!selectedFile) {
        alert("Seleccione un archivo de imagen primero.");
        return;
      }

      setIsUploadingImage(true);
      const idprofesional = usuario.profesional.id;
      const formData = new FormData();
      formData.append("profileImage", selectedFile);
      await lanekApi.put(
        `/profesional/change-photo/${idprofesional}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await getUserPhoto(idprofesional);
    } catch (error) {
      console.error("Error al cargar la imagen:", error);
    } finally {
      setIsUploadingImage(false);
      setHasChanges(false);
      onClose();
    }
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <Flex
        align="center"
        bg={"#fff"}
        borderRadius={"16px"}
        boxShadow="md"
        mb={8}
        padding={7}
      >
        <input
          type="file"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
        />

        <Avatar
          bg="twitter.300"
          borderColor="black"
          borderWidth={1}
          mr={4}
          size={"2xl"}
          src={previewURL ? previewURL : user_photo}
        >
          <AvatarBadge
            aria-label="change image"
            as={IconButton}
            color={"#333"}
            icon={<FaPenToSquare />}
            onClick={handleAvatarClick}
            rounded="full"
            size="sm"
          />
        </Avatar>

        <VStack align={"flex-start"}>
          <Heading as="h2" size={"xl"} color={"#333"}>
            {fullname}
          </Heading>
          <Text>{item?.email}</Text>
        </VStack>

        {hasChanges && selectedFile && (
          <Button colorScheme="twitter" alignSelf="flex-end" onClick={onOpen}>
            Guardar Cambios
          </Button>
        )}
      </Flex>

      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>Confirmación</ModalHeader>
          <ModalBody>
            ¿Estás seguro de que deseas guardar los cambios?
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="twitter"
              mr={3}
              isLoading={isUploadingImage}
              onClick={confirmUpload}
            >
              Guardar Cambios
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfileUserAvatar;

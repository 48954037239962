import React from "react";
import { Flex, Image } from "@chakra-ui/react";

export const ImageMaker = ({ images }) => {
  return (
    <Flex borderRadius="md" borderWidth={1} boxShadow="md" mt={10}>
      <Image
        alt="sometext"
        boxSize={"xs"}
        key={images.key}
        py={4}
        objectFit="contain"
        src={images}
        width="100%"
      />
    </Flex>
  );
};

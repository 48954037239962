import React, { useState } from "react";
// import { ChatButton } from "../components/ChatButton";
// import { ChatBot } from "../components/Chatbot";
import {
  Box,
  Flex,
  Grid,
  Text,
  Progress,
  IconButton,
  ScaleFade,
  Container,
} from "@chakra-ui/react";
// import GaugeChart from "react-gauge-chart";
import { FaDatabase, FaUsers } from "react-icons/fa";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { RenderLoading } from "../components/RenderAlert";
import { SessionManager } from "../components/SessionManager";

ChartJS.register(ArcElement, Tooltip, Legend);

const HomeScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const data = {
    labels: ["Muy malo", "Malo", "Regular", "Bueno", "Muy bueno", "Excelente"],
    datasets: [
      {
        label: "Opiniones de pacientes",
        data: [1, 3, 3, 1, 30, 40],
        backgroundColor: [
          "red",
          "rgba(245, 104, 12 )",
          "rgba(255, 206, 86, 0.2)",
          "rgba(209, 225, 13)",
          "rgba(101, 205, 13)",
          "green",
        ],
        borderColor: [
          "red",
          "rgba(245, 104, 12 )",
          "rgba(255, 206, 86, 0.2)",
          "rgba(209, 225, 13)",
          "rgba(101, 205, 13)",
          "green",
        ],
        borderWidth: 1,
        pointStyle: "circle", // Establecer el estilo del punto como círculo
        pointRadius: 5, // Tamaño del radio del círculo
        pointHoverRadius: 7,
      },
    ],
  };

  const dataLine = {
    labels: [
      "Enero 2023",
      "Febrero 2023",
      "Marzo 2023",
      "Abril 2023",
      "Mayo 2023",
      "Junio 2023",
      "Julio 2023",
    ],
    datasets: [
      {
        label: "Pacientes atendidos",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const optionsLine = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <>
      {/* <h2>Lanek</h2> */}
      {/* <ChatBot /> */}
      {/* <ChatButton /> */}
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <>
          <SessionManager />
          <ScaleFade initialScale={0.9} in={true}>
            <Container
              maxW={"100%"}
              paddingLeft={{ base: 4, xl: 0 }}
              paddingRight={4}
            >
              <Box>
                <Flex
                  align="center"
                  bg="#fff"
                  borderRadius="16px"
                  borderWidth={1}
                  boxShadow="md"
                  direction={{ base: "column", sm: "row" }}
                  height="fit-content"
                  justify="space-between"
                  padding={4}
                  width="100%"
                >
                  <Grid templateColumns="repeat(4, 1fr)" gap={14}>
                    <Flex
                      maxW="sm"
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      p="4"
                      boxShadow="md"
                    >
                      <Box flex="1">
                        <Text fontSize="xl" fontWeight="bold">
                          Examenes Procesados
                        </Text>
                        <Text mt="2" color="gray.600">
                          50
                        </Text>
                        <Progress mt="2" value={50} />
                      </Box>

                      <IconButton
                        colorScheme="teal"
                        aria-label="Call Segun"
                        size="lg"
                        icon={<FaUsers />}
                        pointerEvents="none"
                      />
                    </Flex>
                    <Flex
                      maxW="sm"
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      p="4"
                      boxShadow="md"
                    >
                      <Box flex="1">
                        <Text fontSize="xl" fontWeight="bold">
                          Examenes no procesados
                        </Text>
                        <Text mt="2" color="gray.600">
                          15
                        </Text>
                        <Progress mt="2" value={15} />
                      </Box>

                      <IconButton
                        colorScheme="teal"
                        aria-label="Call Segun"
                        size="lg"
                        icon={<FaUsers />}
                        pointerEvents="none"
                      />
                    </Flex>
                    <Flex
                      maxW="sm"
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      p="4"
                      boxShadow="md"
                    >
                      <Box flex="1">
                        <Text fontSize="xl" fontWeight="bold">
                          Pacientes
                        </Text>
                        <Text mt="2" color="gray.600">
                          40
                        </Text>
                        <Progress mt="2" value={360} />
                      </Box>

                      <IconButton
                        colorScheme="teal"
                        aria-label="Call Segun"
                        size="lg"
                        icon={<FaDatabase />}
                        pointerEvents="none"
                      />
                    </Flex>
                    <Flex
                      maxW="sm"
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      p="4"
                      boxShadow="md"
                    >
                      <Box flex="1">
                        <Text fontSize="xl" fontWeight="bold">
                          Examenes realizados
                        </Text>
                        <Text mt="2" color="gray.600">
                          98
                        </Text>
                        <Progress mt="2" value={98} />
                      </Box>

                      <IconButton
                        colorScheme="teal"
                        aria-label="Call Segun"
                        size="lg"
                        icon={<FaDatabase />}
                        pointerEvents="none"
                      />
                    </Flex>
                  </Grid>
                </Flex>

                {/* Sección inferior */}
                <Flex mt={8}>
                  {/* Lado izquierdo con diagrama */}
                  <Box
                    bg="#fff"
                    borderRadius="16px"
                    borderWidth={1}
                    boxShadow="md"
                    direction={{ base: "column", sm: "row" }}
                    height="100%"
                    justify="space-between"
                    padding={4}
                    width="full"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box align="center" padding={4}>
                      <Text fontSize="x" fontWeight="bold">
                        Nivel de satifacción de los pacientes
                      </Text>
                    </Box>
                    <Doughnut data={data} />
                  </Box>
                  <Box mx={4} />
                  {/* Lado derecho con diagrama */}
                  <Box
                    bg="#fff"
                    borderRadius="16px"
                    borderWidth={1}
                    boxShadow="md"
                    direction={{ base: "column", sm: "row" }}
                    height="100%"
                    justify="space-between"
                    padding={4}
                    width="full"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box align="center" padding={4}>
                      <Text fontSize="x" fontWeight="bold">
                        Pacientes atendidos por Jesus Fuentes
                      </Text>
                    </Box>
                    <Box w="100%">
                      {/* <h2>Gráfico de Área</h2> */}
                      <Line data={dataLine} options={optionsLine} />
                    </Box>
                  </Box>
                </Flex>
              </Box>
            </Container>
          </ScaleFade>
        </>
      )}
    </>
  );
};

export default HomeScreen;

import React, { useContext, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FaUserEdit } from "react-icons/fa";
import { StoreContext } from "../../context/store/StoreContext";
import { CuentaForm } from "../Forms/CuentaForm";
import { ButtonWithIcon } from "../CustomButtons";
import { formatearRut } from "../../utils/formatearRut";

export const EditarCuenta = ({ cuenta, tipos }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateOrganization } = useContext(StoreContext);

  const [isHabilitado, setIsHabilitado] = useState(cuenta.is_habilitado);
  const [isLoading, setIsLoading] = useState(false);
  const [rut, setRut] = useState(cuenta.rut);
  const [options, setOptions] = useState(cuenta.tipo_examen);

  const handleRutChange = (event) => {
    const formattedRut = formatearRut(event.target.value);
    setRut(formattedRut);
  };

  const handleCheckboxChange = (option) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  };

  const handleUpdate = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const dataCuenta = {
      ...values,
      rut: rut,
      tipo_examen: options,
      is_habilitado: isHabilitado,
    };
    await updateOrganization(dataCuenta);
    setSubmitting(false);
    setIsLoading(false);
    setIsHabilitado(false);
    onClose();
  };
  return (
    <>
      <ButtonWithIcon fn={onOpen} titleIcon={FaUserEdit} titleButton="Editar" />

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editando organización</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CuentaForm
              handleRutChange={handleRutChange}
              initialValues={cuenta}
              isLoading={isLoading}
              onSubmit={handleUpdate}
              habilitado={isHabilitado}
              setHabilitado={setIsHabilitado}
              rut={rut}
              setOptions={handleCheckboxChange}
              options={options}
              tipos={tipos}
              stage="editar"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useState } from "react";
import {
  Box,
  Container,
  Input,
  InputGroup,
  InputRightElement,
  ScaleFade,
  useColorModeValue,
} from "@chakra-ui/react";
import TablaPaciente from "../components/Tablas/TablaPaciente";
import { RenderLoading } from "../components/RenderAlert";
import { SessionManager } from "../components/SessionManager";
import { useStore } from "../hooks/useStore";
import { useAuth } from "../hooks/useAuth";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { CrearPaciente } from "../components/Ui";

const PatientsScreen = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { rol, usuario } = useAuth();
  const { getsPatients, getsPatientsByOrganization, pacientes } = useStore();

  const inputBg = useColorModeValue("white", "navy.800");

  const pacientesFiltrados = () => {
    if (search.length === 0) return pacientes;

    // Si hay algo en la barra de busqueda
    const filtrados = pacientes.filter((paciente) => {
      return (
        paciente.nombre?.toLowerCase().includes(search.toLowerCase()) ||
        paciente.rut?.toString().includes(search)
      );
    });
    return filtrados;
  };

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
  };

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        setIsLoading(true);
        if (rol.nombre === "Master") {
          await getsPatients();
          setIsLoading(false);
          return;
        }
        await getsPatientsByOrganization(usuario.id_organizacion);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPatients();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <Suspense fallback={<h2>🌀 Loading...</h2>}>
          <SessionManager />
          <ScaleFade initialScale={0.9} in={true}>
            <Container
              maxW={"100%"}
              paddingLeft={{ base: 4, xl: 0 }}
              paddingRight={4}
            >
              <Box display="flex" justifyContent="flex-start" mb={4}>
                <InputGroup w="auto">
                  <InputRightElement>
                    <FaMagnifyingGlass />
                  </InputRightElement>
                  <Input
                    bg={inputBg}
                    boxShadow="md"
                    htmlSize={40}
                    size="md"
                    width="auto"
                    variant="search"
                    value={search}
                    onChange={onSearchChange}
                    placeholder="Buscar paciente por nombre o rut"
                  />
                </InputGroup>

                <CrearPaciente />
              </Box>

              <TablaPaciente patients={pacientesFiltrados()} />
            </Container>
          </ScaleFade>
        </Suspense>
      )}
    </>
  );
};

export default PatientsScreen;

/* 
  maneja las rutas privadas de la aplicacion
*/

import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Main from "../layouts/Main";
// import SplashScreen from "../screens/SplashScreen";

const PrivateRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const [initialRedirectDone, setInitialRedirectDone] = useState(false);
  // const [showSplash, setShowSplash] = useState(false);

  useEffect(() => {
    const getInitialPath = () => {
      if (isAuthenticated) {
        return "/inicio";
      }

      return "/auth/login";
    };

    // Solo redirige si está autenticado y aún no ha accedido a ninguna ruta
    if (isAuthenticated && !initialRedirectDone) {
      const lastPath = sessionStorage.getItem("lastPath") || getInitialPath();
      navigate(lastPath, { replace: true });
      setInitialRedirectDone(true);
    }
  }, [isAuthenticated, initialRedirectDone, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      sessionStorage.setItem("lastPath", location.pathname);
    }
  }, [isAuthenticated, location.pathname]);

  //* CODIGO PARA IMPLEMENTAR UN SPLASH SCREEN LUEGO DE QUE EL USUARIO INICIO SESION
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     setShowSplash(true); // Mostrar SplashScreen si el usuario está autenticado
  //     const timer = setTimeout(() => {
  //       setShowSplash(false); // Ocultar SplashScreen después de un tiempo determinado (puedes ajustarlo)
  //       navigate(location.pathname); // Redirigir a la ruta actual después de ocultar el SplashScreen
  //     }, 3000); // Cambia el tiempo según tus necesidades

  //     return () => clearTimeout(timer);
  //   }
  // }, [isAuthenticated, navigate, location.pathname]);

  // if (showSplash) {
  //   return <SplashScreen />;
  // }

  return isAuthenticated ? (
    <Main>
        <Outlet />
    </Main>
  ) : (
    <Navigate to="/auth/login" />
  );
};

export default PrivateRouter;

import { useLocation, useNavigate } from "react-router-dom";
import { Flex, Icon, Link, useMediaQuery } from "@chakra-ui/react";

const NavItem = ({ icon, children, navigateTo, onClose, ...rest }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const activeRoute = (routeName) => {
    return pathname.startsWith(routeName) ? "active" : "";
  };

  const [isLargeScreen] = useMediaQuery("(min-width: 1280px)");

  return (
    <>
      {isLargeScreen ? (
        <Link style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
          <Flex
            className={activeRoute(navigateTo) && "button_navegacion--item"}
            align="center"
            p="4"
            mb={1}
            borderRadius="lg"
            role="group"
            cursor="pointer"
            onClick={() => navigate(`${navigateTo}`)}
            _hover={{
              bg: "cyan.400",
              color: "white",
            }}
            {...rest}
          >
            {icon && (
              <Icon
                mr="4"
                boxSize={5}
                fontSize="16"
                _groupHover={{
                  color: "white",
                }}
                as={icon}
              />
            )}
            {children}
          </Flex>
        </Link>
      ) : (
        <Link style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
          <Flex
            className={activeRoute(navigateTo) && "button_navegacion--item"}
            align="center"
            p="4"
            mx="2"
            borderRadius="lg"
            role="group"
            cursor="pointer"
            onClick={() => {
              navigate(`${navigateTo}`);
              onClose();
            }}
            _hover={{
              bg: "cyan.400",
              color: "white",
            }}
            {...rest}
          >
            {icon && (
              <Icon
                mr="4"
                boxSize={5}
                fontSize="16"
                _groupHover={{
                  color: "white",
                }}
                as={icon}
              />
            )}
            {children}
          </Flex>
        </Link>
      )}
    </>
  );
};

export default NavItem;

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

export const LineChart = ({
  datos,
  dataFeature,
  fullData,
  descripcionF,
  fullDataTime,
  normativeValues,
  stats,
}) => {
  const plugin = {
    id: "custom_canvas_background_color",
    beforeDraw: (chart, args, options) => {
      const { ctx } = chart;
      const chartArea = chart.chartArea;
      ctx.save();
      ctx.fillStyle = options.color;

      // Valores máximo y mínimo
      const minValue = normativeValues[0].min;
      const maxValue = normativeValues[0].max;

      // Iterar sobre los datos para determinar el rango y colorear en consecuencia
      const yScale = chart.scales.y;
      const minValuePixel = yScale.getPixelForValue(minValue);
      const maxValuePixel = yScale.getPixelForValue(maxValue);

      // Pintar el área del gráfico
      ctx.fillRect(
        chartArea.left,
        chartArea.top,
        chartArea.right - chartArea.left,
        chartArea.bottom - chartArea.top
      );

      // Colorear el área dentro del rango normativo
      ctx.clearRect(
        chartArea.left,
        minValuePixel,
        chartArea.right - chartArea.left,
        maxValuePixel - minValuePixel
      );

      ctx.restore();
    },
    defaults: {
      color: "beige",
    },
  };

  // escalado en el eje y
  const minValue = Math.min(...fullData[0]) - 30;
  const maxValue = Math.max(...fullData[0]) + 30;
  const suggestedMin = Math.floor(minValue / 20) * 20;
  const suggestedMax = Math.ceil(maxValue / 20) * 20;

  let tiempoEtiquetas = fullDataTime;

  const tooltipVariant = useBreakpointValue({
    base: false,
    md: true,
  });

  const pointRadiusVariant = useBreakpointValue({
    base: 0,
    md: undefined,
  });

  if (datos?.exam_type === "abma-lite" && normativeValues[0] !== undefined) {
    // Datos del gráfico
    const dataset = {
      labels: tiempoEtiquetas,
      datasets: [
        {
          label: dataFeature,
          data: fullData[0],
          pointRadius: pointRadiusVariant,
          borderColor: "#0BC5EA",
          backgroundColor: "#00A3C4",
        },
      ],
    };

    const config = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: descripcionF[0].title.toUpperCase(),
          font: {
            size: 18,
            fontWeight: "bold",
          },
        },
        tooltip: {
          enabled: tooltipVariant,
        },
      },
      scales: {
        x: {
          type: "linear",
          display: true,
          title: {
            display: true,
            text: `${descripcionF[0]?.xaxis} [${descripcionF[0]?.xunit}]`,
            font: {
              size: 14,
            },
          },
          min: Math.min(...tiempoEtiquetas),
          max: Math.max(...tiempoEtiquetas),
        },
        y: {
          type: "linear",
          display: true,
          title: {
            display: true,
            text: `${descripcionF[0]?.yaxis} [${descripcionF[0]?.yunit}]`,
          },
          min: suggestedMin,
          max: suggestedMax,
          // stepSize: 10,
        },
      },
    };

    return (
      <Flex
        bg="#fff"
        borderRadius="16px"
        borderWidth={1}
        boxShadow="md"
        justify="center"
        height={{ base: "280px", md: "480px" }}
        width="full"
      >
        <Box padding={2} width="100%" height="100%">
          <Line
            data={dataset}
            options={config}
            plugins={[plugin]}
            updateMode="resize"
          />
        </Box>
      </Flex>
    );
  } else {
    let labels = Array.from(
      { length: fullData[0].length },
      (_, index) => index * 1
    );
    const tiempoEtiquetas = labels.filter((valor, index) => index % 1 === 0);

      // Datos del gráfico
    const dataset = {
      labels: tiempoEtiquetas,
      datasets: [
        {
          label: dataFeature,
          data: fullData[0],
          pointRadius: pointRadiusVariant,
          borderColor: "#0BC5EA",
          backgroundColor: "#00A3C4",
        },
      ],
    };

    const config = {
      responsive: true,
      maintainAspectRatio:false,
      plugins: {
        legend: {
          position: "top",
        },

        title: {
          display: true,
          text: descripcionF[0].title,
          font: {
            size: 24,
            fontWeight: "bold",
          },
        },
      },
      scales: {
        x: {
          type: "linear",
          ticks: {
            stepSize: 100,
          },
          display: true,
          title: {
            display: true,
            text: descripcionF[0]?.xaxis + ` [${descripcionF[0]?.xunit}]`,
          },
        },
        y: {
          type: "linear",
          display: true,
          title: {
            display: true,
            text: descripcionF[0]?.yaxis + ` [${descripcionF[0]?.yunit}]`,
          },
        },
      },
    };

    

    return (
      <>
        <Flex
          bg="#fff"
          borderRadius="16px"
          borderWidth={1}
          boxShadow="md"
          mb={6}
          justify="center"
          height={{ base: "280px", md: "480px" }}
          width="full"
        >
          <Box padding={2} width="100%" height="100%">
            <Line data={dataset} options={config} updateMode="resize" />
          </Box>
        </Flex>
      </>
    );
  }
};

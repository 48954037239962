import React from "react";
import {
  Box,
  Flex,
  Heading,
  Input,
  FormControl,
  FormLabel,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useAuth } from "../../hooks/useAuth";
import { Field, Form, Formik } from "formik";
import { useStore } from "../../hooks/useStore";

const ProfileUserInfo = ({ user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { rol } = useAuth();
  const { updateOrganization, updateProfessional } = useStore();

  const initialValues = {
    id: user?.id,
    id_organizacion: user?.id_organizacion,
    nombre: user?.nombre,
    apellidos: user?.apellidos,
    email: user?.email,
    rut: user?.rut,
    telefono: user?.telefono,
  };

  const capitalize = (str) => {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const formatRut = (rut) => {
    // Eliminar caracteres no permitidos y formatear según el formato deseado
    rut = rut.replace(/[^\dKk]/g, ""); // Permitir "K" o "k"
    const digits = rut.slice(0, -1).replace(/\D/g, "");
    const verifier = rut.slice(-1).toUpperCase();

    if (digits.length < 1) {
      return ""; // Si no hay dígitos, no hay nada que formatear
    }

    // Formatear RUT con separador de miles
    const formattedDigits = digits.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    return formattedDigits + "-" + verifier;
  };

  const onSave = async (values, { setSubmitting }) => {
    const data = values;
    console.log(data);
    rol.nombre === "Administrador"
      ? await updateOrganization(data)
      : await updateProfessional(data);

    setSubmitting(false);
    onClose();
  };

  return (
    <>
      {user && (
        <Flex
          direction={"column"}
          bg={"#fff"}
          color={"#333"}
          align="center"
          borderRadius={"16px"}
          boxShadow="md"
          mb={8}
          padding={7}
        >
          <Heading w="100%" fontWeight={"bold"} size={"md"} mb={4}>
            Información básica
          </Heading>

          <Box w={"full"}>
            <Formik initialValues={initialValues} onSubmit={onSave}>
              {({
                isSubmitting,
                dirty,
                values,
                resetForm,
                handleSubmit,
                handleChange,
                setFieldValue,
              }) => (
                <Form>
                  <Flex direction="row" justify="space-between" mb={4}>
                    <Field name="nombre">
                      {({ field }) => (
                        <FormControl mr={2}>
                          <FormLabel
                            htmlFor="nombre"
                            fontWeight="semibold"
                            color="gray.700"
                            _dark={{
                              color: "gray.50",
                            }}
                          >
                            Nombre
                          </FormLabel>
                          <Input
                            {...field}
                            type="text"
                            id="nombre"
                            shadow="sm"
                            size={["sm", "md"]}
                            w="full"
                            rounded="md"
                            onBlur={() => {
                              setFieldValue(
                                "nombre",
                                capitalize(values.nombre)
                              );
                            }}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field name="apellidos">
                      {({ field }) => (
                        <FormControl ml={2}>
                          <FormLabel
                            htmlFor="apellidos"
                            fontWeight="semibold"
                            color="gray.700"
                            _dark={{
                              color: "gray.50",
                            }}
                          >
                            Apellidos
                          </FormLabel>
                          <Input
                            {...field}
                            type="text"
                            id="apellidos"
                            shadow="sm"
                            size={["sm", "md"]}
                            w="full"
                            rounded="md"
                            onBlur={() => {
                              setFieldValue(
                                "apellidos",
                                capitalize(values.apellidos)
                              );
                            }}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Flex>

                  <Field name="email">
                    {({ field }) => (
                      <FormControl mb={4}>
                        <FormLabel
                          htmlFor="email"
                          fontWeight="semibold"
                          color="gray.700"
                          _dark={{
                            color: "gray.50",
                          }}
                        >
                          Correo Electrónico
                        </FormLabel>
                        <Input
                          {...field}
                          type="email"
                          id="email"
                          shadow="sm"
                          size={["sm", "md"]}
                          w="full"
                          rounded="md"
                        />
                      </FormControl>
                    )}
                  </Field>

                  <Flex direction="row" justify="space-between" mb={4}>
                    <Field name="rut">
                      {({ field }) => (
                        <FormControl mr={2}>
                          <FormLabel
                            htmlFor="rut"
                            fontWeight="semibold"
                            color="gray.700"
                            _dark={{
                              color: "gray.50",
                            }}
                          >
                            RUT
                          </FormLabel>
                          <Input
                            {...field}
                            type="text"
                            id="rut"
                            shadow="sm"
                            size={["sm", "md"]}
                            w="full"
                            rounded="md"
                            onBlur={() => {
                              const formattedRut = formatRut(values.rut);
                              setFieldValue("rut", formattedRut);
                            }}
                            onChange={(e) => {
                              if (e.target.value.length <= 12) {
                                handleChange(e);
                              }
                            }}
                          />
                        </FormControl>
                      )}
                    </Field>

                    <Field name="telefono">
                      {({ field }) => (
                        <FormControl ml={2}>
                          <FormLabel
                            htmlFor="telefono"
                            fontWeight="semibold"
                            color="gray.700"
                            _dark={{
                              color: "gray.50",
                            }}
                          >
                            Teléfono
                          </FormLabel>
                          <Input
                            {...field}
                            type="number"
                            id="telefono"
                            shadow="sm"
                            size={["sm", "md"]}
                            w="full"
                            rounded="md"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Flex>

                  <Flex justify={"flex-end"}>
                    <Button
                      colorScheme="twitter"
                      isLoading={isSubmitting}
                      mr={4}
                      isDisabled={!dirty}
                      onClick={onOpen}
                    >
                      Guardar Cambios
                    </Button>

                    <Button
                      colorScheme="gray"
                      onClick={() => {
                        resetForm();
                      }}
                      isDisabled={!dirty}
                    >
                      Cancelar
                    </Button>
                  </Flex>

                  <Modal
                    isCentered
                    isOpen={isOpen}
                    onClose={onClose}
                    motionPreset="slideInBottom"
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Confirmación</ModalHeader>
                      <ModalBody>
                        ¿Estás seguro de que deseas finalizar el registro?
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          colorScheme="twitter"
                          mr={3}
                          onClick={() => {
                            onClose();
                            handleSubmit();
                          }}
                        >
                          Confirmar
                        </Button>

                        <Button variant="ghost" onClick={onClose}>
                          Cancelar
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Form>
              )}
            </Formik>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default ProfileUserInfo;

import React, { useContext, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  IconButton,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { FaCommentDots } from "react-icons/fa";
import { StoreContext } from "../../context/store/StoreContext";

export const FeedbackModal = () => {
  const { createFeedback } = useContext(StoreContext);
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [image, setImage] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async () => {
    setIsFetching(true);

    const data = {
      titulo: title,
      comentario: comment,
    };
    try {
      await createFeedback(image, data);
      setTitle("");
      setComment("");
      setIsFetching(false);

      toast({
        title: "Feedback enviado!",
        description: "Se han recibido los datos correctamente.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error.response.data);
    }

    // Cerramos el modal
    handleCloseModal();
  };

  return (
    <>
      <Tooltip hasArrow label="Déjanos un feedback">
        <IconButton
          onClick={handleOpenModal}
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FaCommentDots />}
        />
      </Tooltip>

      <Modal isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Gestionar feedback</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Título</FormLabel>
              <Input type="text" value={title} onChange={handleTitleChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Comentario</FormLabel>
              <Textarea value={comment} onChange={handleCommentChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Imagen</FormLabel>
              <Input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isFetching}
            >
              Enviar
            </Button>
            <Button ml={2} onClick={handleCloseModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

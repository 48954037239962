/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Container, Heading, ScaleFade } from "@chakra-ui/react";
import { TablaFeedbacks } from "../components/Tablas/TablaFeedbacks";
import { RenderLoading } from "../components/RenderAlert";
import { StoreContext } from "../context/store/StoreContext";

const FeedbackScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { feedbacks, getFeedbacks } = useContext(StoreContext);

  useEffect(() => {
    const fetchData = async () => {
      await getFeedbacks();
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            bg={"white"}
            rounded={"md"}
            shadow={"md"}
            py={10}
            maxW={"full"}
          >
            <Heading as={"h2"} mb={6}>
              Feedbacks
            </Heading>

            <TablaFeedbacks feedbacks={feedbacks} />
          </Container>
        </ScaleFade>
      )}
    </>
  );
};

export default FeedbackScreen;
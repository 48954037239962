/* 
    maneja todas las funciones del authcontext que engloba todas las acciones de logueo o registro
*/

import React, { useReducer } from "react";
import { useToast } from "@chakra-ui/react";
import Cookies from "js-cookie";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";
import { lanekApi } from "../../api/lanekApi";

const AuthState = ({ children }) => {
  const toast = useToast();

  const userDataCookie = Cookies.get("userData");
  let initialUserData = {};

  if (userDataCookie) {
    initialUserData = JSON.parse(userDataCookie);
  }
  const initialState = {
    usuario: initialUserData.usuario || {},
    rol: initialUserData.rol || {},
    exams: initialUserData.exams || {},
    isAuthenticated: initialUserData.isAuthenticated || false,
    isLoginLoading: false,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const startLogin = async (email, password) => {
    console.log('login request')
    try {
      const { data } = await lanekApi.post("/auth/login/", {
        email: email.trim(),
        password: password.trim(),
      });
      window.localStorage.setItem("token", data.token);
      const { user, examenes } = data;
      console.log(data)

      const userData = {
        usuario: user,
        rol: user.rol,
        exams: examenes,
        token: data.token,
        isAuthenticated: true,
      };
      Cookies.set("userData", JSON.stringify(userData), {
        expires: 1 / 96,
        secure: true,
      });

      dispatch({
        type: "LOGIN_SUCCESS",
        payload: data,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const logoutRequest = async () => {
    Cookies.remove("userData");
    sessionStorage.clear();
    dispatch({
      type: "LOGOUT",
      payload: [],
    });
    // window.sessionStorage.clear();
    // window.localStorage.clear();
  };

  const startLoading = (status) => {
    dispatch({
      type: "LOGIN_LOADING",
      payload: status,
    });
  };

  const handleError = (error) => {
    dispatch({
      type: "LOGIN_LOADING",
      payload: false,
    });

    console.log(error);

    toast({
      description: error.response?.data?.message || "An error occurred",
      status: "error",
      position: "bottom-left",
      variant: "left-accent",
      duration: 4000,
      isClosable: true,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        startLogin,
        logoutRequest,
        startLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
// import GaugeChart from "react-gauge-chart";
import "chartjs-adapter-moment";
import { BubbleChart } from "./BubbleChart";
import Speedometer from "react-d3-speedometer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  TimeScale
);

export const AllLineChart = ({ fullData, examType, normativeValues, datos, arrayFeatures }) => {  
  
  // const gaugeValue = 150; // Puedes cambiar este valor según tus necesidades
  // // Convertir el valor de la aguja al rango de porcentaje (0 a 1)
  // const percentValue = gaugeValue / 300;

  if (examType === "avm") {
    let labels = Array.from({ length: 567 }, (_, index) => index * 1);
    const tiempoEtiquetas = labels.filter((index) => index % 1 === 0);

    const segmentColors = [
      "#FF0000", // Rojo
      "#FF3300", // Amarillo
      "#FF9900", // Verde
      "#FFCC00", // Amarillo
      "#FFFF00",  // Rojo
      "#CCFF00",
      "#99FF00",
      "#66FF00",
      "#99FF00",
      "#CCFF00",
      "#FFFF00",
      "#FFCC00",
      "#FF9900",
      "#FF3300",
      "#FF0000",
    ];

    return (
      <>
        {fullData.map(
          ({ data, ...props }) =>
            data.length > 0 && (
              <>
              {console.log("obteniendo datos", props)}
                <Box
                  bg="#fff"
                  borderRadius="16px"
                  borderWidth={1}
                  boxShadow="md"
                  direction={{ base: "column", sm: "row" }}
                  //   height="100%"
                  height="100%"
                  justify="space-between"
                  padding={4}
                  width="full"
                >
                  <LineChartAvmCustom
                    featureUniq={props}
                    data={data}
                    timeTag={tiempoEtiquetas}
                    key={props.title}
                  />
                </Box>
                <Box
                  bg="#fff"
                  borderRadius="16px"
                  borderWidth={1}
                  boxShadow="md"
                  direction={{ base: "column", sm: "row" }}                  
                  height="100%"
                  justify="space-between"
                  padding={4}
                  width="full"                  
                  justifyContent="center" 
                  alignItems="center"                   
                >
                  <Box align="center">
                    <Text fontSize="x" fontWeight="bold">
                      {props.title}
                    </Text>
                  </Box>
                  {/* <Box align="center">
                    <Text fontSize="x">Descripción</Text>
                  </Box> */}

                  {/* <GaugeChart
                    id="gauge-chart3"
                    nrOfLevels={15}
                    // nrOfLevels={20}
                    // colors={["orange", "green", "red"]}
                    colors={[
                      "#00FF00",
                      "#33FF00",
                      "#66FF00",
                      "#99FF00",
                      "#CCFF00",
                      "#FFFF00",
                      "#FFCC00",
                      "#FF9900",
                      "#FF6600",
                      "#FF3300",
                      "#FF0000",
                    ]}
                    arcWidth={0.2}
                    // percent={0.37}
                    textColor={"black"}
                    arcPadding={0.1}
                    cornerRadius={3}
                    percent={percentValue}
                    formatTextValue={(value) => `${value}`}
                  /> */}
                  <Center padding={4}>
                  <Speedometer                  
                    value={Math.floor(props.median)}                    
                    // value={140}
                    minValue={0}
                    // minValue={Math.floor(props.min)}
                    maxValue={220}
                    // maxValue={Math.floor(props.max)}
                    needleColor="red"
                    startColor="green"
                    // segments={15}
                    segments={segmentColors.length}
                    segmentColors={segmentColors}
                    endColor="red"
                    needleTransitionDuration={4000}
                    needleTransition="easeElastic"
                    width={300} // Ancho del velocímetro
                    height={180}
                  />                                    
                  </Center>
                  <BubbleChart />
                </Box>
              </>
            )
        )}
      </>
    );
  }
  if (examType === "abma-lite") {
    return (
      <>
        {fullData.map(
          ({ data, ...props }) =>
            data.length > 0 && (
              <LineChartAbmaCustom
                featureUniq={props}
                data={data}
                normativeValues={normativeValues}
                key={props.title}
              />
            )
        )}
      </>
    );
  }
};

const LineChartAbmaCustom = ({ data, featureUniq, normativeValues }) => {
  const { feature, title, time, xaxis, xunit, yaxis, yunit } = featureUniq;

  const plugin = {
    id: "custom_canvas_background_color",
    beforeDraw: (chart, args, options) => {
      const { ctx } = chart;
      const chartArea = chart.chartArea;
      ctx.save();
      ctx.fillStyle = options.color;

      // Valores máximo y mínimo
      const minValue = normativeValues[0].min;
      const maxValue = normativeValues[0].max;

      // Iterar sobre los datos para determinar el rango y colorear en consecuencia
      const yScale = chart.scales.y;
      const minValuePixel = yScale.getPixelForValue(minValue);
      const maxValuePixel = yScale.getPixelForValue(maxValue);

      // Pintar el área del gráfico
      ctx.fillRect(
        chartArea.left,
        chartArea.top,
        chartArea.right - chartArea.left,
        chartArea.bottom - chartArea.top
      );

      // Colorear el área dentro del rango normativo
      ctx.clearRect(
        chartArea.left,
        minValuePixel,
        chartArea.right - chartArea.left,
        maxValuePixel - minValuePixel
      );

      ctx.restore();
    },
    defaults: {
      color: "beige",
    },
  };

  const dataset = {
    labels: time,
    datasets: [
      {
        label: feature,
        data: [...data],
        pointRadius: 0,
        borderColor: "#0BC5EA",
        backgroundColor: "#00A3C4",
      },
    ],
  };

  const config = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title.toUpperCase(),
        font: {
          size: "16x",
          fontWeight: "bold",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        type: "linear",
        ticks: {
          stepSize: 2,
          font: {
            size: 12,
          },
        },
        display: true,
        title: {
          display: true,
          text: `${xaxis} [${xunit}]`,
          font: {
            size: 14,
          },
        },
        min: 0, // Establece el valor mínimo del eje x
        max: Math.max(...time),
      },
      y: {
        type: "linear",
        display: true,
        title: {
          display: true,
          text: yaxis + ` [${yunit}]`,
          font: {
            size: 14,
          },
        },
        min: 0 - 20, // Establece el valor mínimo del eje y
        max: Math.max(...data) + 20,
      },
    },
  };

  return (
    <Flex
      bg="#fff"
      borderRadius="16px"
      boxShadow="md"
      justify="center"
      mb={4}
      h="280px"
      w="full"
    >
      <Box padding={2} width={"100%"} h="100%">
        <Line
          data={dataset}
          options={config}
          plugins={[plugin]}
          updateMode="resize"
        />
      </Box>
    </Flex>
  );
};

const LineChartAvmCustom = ({ featureUniq, data, timeTag }) => {
  const { feature, title, xaxis, xunit, yaxis, yunit } = featureUniq;

  const dataset = {
    labels: timeTag,
    datasets: [
      {
        label: feature,
        data: [...data],
        pointRadius: 0,
        borderColor: "#0BC5EA",
        backgroundColor: "#00A3C4",
      },
    ],
  };

  const config = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title.toUpperCase(),
        font: {
          size: "16x",
          fontWeight: "bold",
        },
      },
      tooltip: {
        enabled: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "xy",
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        ticks: {
          stepSize: 100,
          font: {
            size: 12,
          },
        },
        display: true,
        title: {
          display: true,
          text: `${xaxis} [${xunit}]`,
          font: {
            size: 14,
          },
        },
      },
      y: {
        type: "linear",
        display: true,
        title: {
          display: true,
          text: `${yaxis} [${yunit}]`,
          font: {
            size: 14,
          },
        },
      },
    },
  };

  return (
    <Flex
      bg="#fff"
      borderRadius="16px"
      boxShadow="md"
      justify="center"
      mb={4}
      h="280px"
      w="full"
    >
      <Box padding={2} width={"100%"} h="100%">
        <Line data={dataset} options={config} updateMode="resize" />
      </Box>
    </Flex>
  );
};

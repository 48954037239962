import React from "react";
import {
  Container,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import Plot from "react-plotly.js";
import * as ss from "simple-statistics";

export const BoxPlotDataTable = ({ dataFeature, stats, fullData }) => {
  const capitalizeFirst = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return null;
    }
  };

  const widthVariant = useBreakpointValue({
    base: 280,
    sm: 300,
    md: 400,
  });

  const heightVariant = useBreakpointValue({
    base: 340,
    md: 420,
  });

  let arrayStats = [];
  Object.entries(stats[0]).forEach((m) => {
    if (m[0] === "min" || m[0] === "median" || m[0] === "max") {
      arrayStats.push(m[1]);
    }
  });

  const arraySinNulos = fullData[0].filter((element) => element !== null);
  const sortedData = arraySinNulos.slice().sort((a, b) => a - b);
  // Calcular Q1 y Q3
  const minimum = ss.min(sortedData);
  const maximum = ss.max(sortedData);
  const median = ss.median(sortedData);
  const q1 = ss.quantile(sortedData, 0.25);
  const q3 = ss.quantile(sortedData, 0.75);

  const diferenciaAbsoluta = Math.abs(minimum - q1);
  const esDiferenciaMayorA1 = diferenciaAbsoluta >= 1;

  return (
    <>
      <Flex
        align="center"
        bg="#fff"
        borderRadius="16px"
        borderWidth={1}
        boxShadow="md"
        direction={{ base: "column", sm: "row" }}
        height="fit-content"
        justify="space-between"
        padding={4}
        width="full"
      >
        <Flex
          direction="column"
          height="100%"
          justify="space-between"
          width="100%"
        >
          <Heading
            alignSelf="flex-start"
            color="#666666"
            fontSize="2xl"
            mb={{ base: 4, md: 0 }}
          >
            Diagrama de caja
          </Heading>

          <Flex
            height="100%"
            justify="center"
            align="center"
            mb={{ base: 4, md: 0 }}
            width="100%"
          >
            {esDiferenciaMayorA1 === true ? (
              <Plot
                data={[
                  {
                    type: "box",
                    y: arraySinNulos,
                    name: dataFeature,
                    hoverinfo: "none",
                    boxpoints: false,
                  },
                  {
                    type: "scatter",
                    mode: "none",
                    hoverinfo: "none",
                    name: "Mínimo y Máximo",
                  },
                ]}
                layout={{
                  autosize: false,
                  width: widthVariant,
                  height: heightVariant,
                  boxmode: "group",
                  paper_bgcolor: "transparent",
                  plot_bgcolor: "transparent",
                  margin: { t: 0, b: 40, l: 40, r: 0 },
                  dragmode: false,

                  xaxis: {
                    showticklabels: false, // Oculta la leyenda del eje x
                  },
                  config: {
                    staticPlot: false,
                    displayModeBar: false,
                    editable: false,
                  },
                  annotations: [
                    {
                      x: 0.5,
                      y: minimum,
                      xref: "x",
                      yref: "y",
                      text: `Mínimo: ${Number(minimum).toFixed(2)}`,
                      showarrow: true,
                      arrowhead: 4,
                      ax: 98,
                      ay: 0,
                      orientation: "v",
                    },
                    {
                      x: 0.5,
                      y: median,
                      xref: "x",
                      yref: "y",
                      text: `Mediana: ${Number(median).toFixed(2)}`,
                      showarrow: true,
                      arrowhead: 4,
                      ax: 102,
                      ay: 0,
                    },
                    {
                      x: 0.5,
                      y: maximum,
                      xref: "x",
                      yref: "y",
                      text: `Máximo: ${Number(maximum).toFixed(2)}`,
                      showarrow: true,
                      arrowhead: 4,
                      ax: 100,
                      ay: 0,
                    },
                    {
                      x: 0.5,
                      y: q1,
                      xref: "x",
                      yref: "y",
                      text: `Q1: ${Number(q1).toFixed(2)}`,
                      showarrow: true,
                      arrowhead: 4,
                      ax: 86,
                      ay: 0,
                    },
                    {
                      x: 0.5,
                      y: q3,
                      xref: "x",
                      yref: "y",
                      text: `Q3: ${Number(q3).toFixed(2)}`,
                      showarrow: true,
                      arrowhead: 4,
                      ax: 85,
                      ay: 0,
                    },
                  ],
                  legend: {
                    orientation: "v",
                    x: 0,
                    y: 13.1,
                  },
                }}
                config={{ displayModeBar: false }}
              />
            ) : (
              <Plot
                data={[
                  {
                    type: "box",
                    y: arraySinNulos,
                    name: dataFeature,
                    hoverinfo: "skip",
                    hovertemplate: false,
                    boxpoints: false,
                  },
                  {
                    type: "scatter",
                    mode: "none",
                    hoverinfo: "skip",
                    name: "Mínimo y Máximo",
                  },
                ]}
                layout={{
                  autosize: false,
                  width: widthVariant,
                  height: heightVariant,
                  boxmode: "group",
                  paper_bgcolor: "transparent",
                  hovermode: false,
                  plot_bgcolor: "transparent",
                  margin: { t: 0, b: 40, l: 40, r: 0 },
                  dragmode: false,
                  xaxis: {
                    showticklabels: false, // Oculta la leyenda del eje x
                  },
                  config: {
                    staticPlot: false,
                    displayModeBar: false,
                    editable: false,
                  },
                  annotations: [
                    {
                      x: 0.7,
                      y: median,
                      xref: "x",
                      yref: "y",
                      text: `Máximo: ${Number(maximum).toFixed(
                        2
                      )}<br />Q3: ${Number(q3).toFixed(
                        2
                      )}<br />Mediana: ${Number(median).toFixed(
                        2
                      )}<br />Q1: ${Number(q1).toFixed(
                        2
                      )}<br />Mínimo: ${Number(minimum).toFixed(2)}`,
                      showarrow: true,
                      arrowhead: 4,
                      align: "left",
                      ax: 0,
                      ay: -40,
                    },
                  ],
                  legend: {
                    orientation: "v",
                    x: 0,
                    y: 13.1,
                  },
                }}
                config={{ displayModeBar: false }}                                
              />
            )}
          </Flex>
          <Text fontWeight="semibold" fontSize={14}>
            Resumen de datos
          </Text>
          <Container maxW="xl">
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    {Object.entries(stats[0]).map((value, index) => (
                      <Td key={index} fontWeight="semibold">
                        {capitalizeFirst(value[0])}
                      </Td>
                    ))}
                  </Tr>
                  {/* <Tr>
                     <Th>Parámetro</Th>
                     <Th>Valor</Th>
                    
                  </Tr> */}
                </Thead>
                <Tbody>
                  <Tr>
                    {Object.entries(stats[0]).map((value, index) => (
                      <Td key={index}>{value[1].toFixed(2)}</Td>
                    ))}
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Container>
        </Flex>
      </Flex>
    </>
  );
};

import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  Box,
  Portal,
  Stack,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { FaHouse, FaUserDoctor, FaUsers } from "react-icons/fa6";
import Sidebar from "../components/Sidebar/Sidebar";

import MainPanel from "../components/Layout/MainPanel";
import PanelContent from "../components/Layout/PanelContent";
import PanelContainer from "../components/Layout/PanelContainer";
import MainNavbar from "../components/navbar/MainNavbar";
import {
  ExamScreen,
  ExamsOptionsScreen,
  PatientScreen,
  PatientsScreen,
  ProfileScreen,
  SummaryScreen,
  UsersScreen,
} from "../screens";

const dashRoutes = [
  {
    path: "/inicio",
    name: "Inicio",
    icon: FaHouse,
    component: SummaryScreen,
    // component: HomeScreen,
    layout: "/admin",
  },
  {
    path: "/pacientes",
    name: "Pacientes",
    icon: FaUsers,
    component: PatientsScreen,
    layout: "/admin",
  },
  {
    path: "/pacientes/:idpaciente",
    component: PatientScreen,
    layout: "/admin",
  },
  {
    path: "/perfil",
    component: ProfileScreen,
    layout: "/admin",
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: FaUserDoctor,
    component: UsersScreen,
    layout: "/admin",
  },
  {
    path: "/paciente/:idpaciente/nuevo_examen",
    component: ExamsOptionsScreen,
    layout: "/admin",
  },
  {
    path: "/paciente/:idpaciente/nuevo_examen/:producto/:idexamen",
    component: ExamScreen,
    layout: "/admin",
  },
];

const Main = (props) => {
  const { ...rest } = props;
  const { colorMode } = useColorMode();

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => (
      <Route
        path={prop.path}
        element={<prop.component />} // Renderizar el componente asociado a la ruta
        key={key}
      />
    ));
  };

  const { onOpen } = useDisclosure();

  return (
    <Box display="flex" bg={"#f6f9fb"} minH={"100vh"}>
      <Sidebar
        routes={dashRoutes}
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            <Box
              w="1px"
              h="20px"
              bg={colorMode === "dark" ? "white" : "gray.700"}
            />
          </Stack>
        }
        display="none"
        {...rest}
      />

      <Box
        flex="1"
        marginLeft={{ base: 0, xl: "300px" }}
        mt={{ base: "65px", md: "100px" }}
        py={5}
      >
        <MainPanel>
          <Portal>
            <MainNavbar
              onOpen={onOpen}
              brandText={getActiveRoute(dashRoutes)}
              secondary={getActiveNavbar(dashRoutes)}
              routes={dashRoutes}
              {...rest}
            />
          </Portal>
          {/* {getRoute() ? (
            <PanelContent>
              <PanelContainer>
                <Routes>{getRoutes(dashRoutes)}</Routes>
              </PanelContainer>
            </PanelContent>
          ) : null} */}
          <PanelContent>
            <PanelContainer>
              <Routes>{getRoutes(dashRoutes)}</Routes>
            </PanelContainer>
          </PanelContent>
        </MainPanel>
      </Box>
    </Box>
  );
};

export default Main;

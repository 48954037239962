/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { FaFileVideo } from "react-icons/fa6";
import { ExamenContext } from "../context/examen/ExamenContext";
import { VideoCreatorOverlay } from "./VideoCreatorOverlay";

export const FileCreator = ({ archivos, setArchivos, setVideoPreview }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { handleNewVideoFile } = useContext(ExamenContext);

  const [isRecording, setIsRecording] = useState(false);
  const [devices, setDevices] = useState({
    videoDevices: [],
    audioDevices: [],
  });
  const [selectedVideoDevice, setSelectedVideoDevice] = useState("");
  const [selectedAudioDevice, setSelectedAudioDevice] = useState("");
  const [timer, setTimer] = useState({ minutes: 0, seconds: 0 });
  const [timerInterval, setTimerInterval] = useState(null);
  const [videoPreviewError, setVideoPreviewError] = useState(false);
  const [audioPreviewError, setAudioPreviewError] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [stream, setStream] = useState(null);
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);

  const videoRef = useRef(null);
  // const audioRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  useEffect(() => {
    if (isRecording) {
      // Iniciar la grabación cuando isRecording sea true
      startRecording();
      startTimer();
    } else {
      // Detener la grabación cuando isRecording sea false
      stopRecording();
      stopTimer();
    }
  }, [isRecording]);

  // Función para iniciar la grabación
  const startRecording = async () => {
    try {
      if (!isVideoEnabled) {
        const audioConstraints = !isAudioEnabled
          ? { audio: { deviceId: selectedAudioDevice } }
          : false;

        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: selectedVideoDevice },
          ...audioConstraints,
        });

        // Actualiza el stream y comienza la grabación solo si la cámara está habilitada
        setStream(stream);

        const recorder = new MediaRecorder(stream);
        mediaRecorderRef.current = recorder;

        const chunks = [];
        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data);
          }
        };

        recorder.onstop = () => {
          if (chunks.length > 0) {
            const recordedBlob = new Blob(chunks, { type: "video/mp4" });

            const newFileObj = {
              blob: recordedBlob,
              name: "nuevo_archivo.mp4",
            };
            const newFile = [...archivos, newFileObj];
            handleNewVideoFile(newFile);
            handleCloseModal();
          }
        };

        recorder.start();
        setIsRecording(true);
      } else {
        // La cámara está deshabilitada, muestra un mensaje al usuario o realiza otras acciones si es necesario
        console.log("La cámara está desactivada, no se iniciará la grabación.");
      }
    } catch (error) {
      console.error("Error al iniciar la grabación:", error);
    }
  };

  // Función para detener la grabación
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }

    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      setStream(null);
    }

    setSelectedVideoDevice("");
    setSelectedAudioDevice("");
    onClose();
  };

  // Función para iniciar el temporizador
  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        const seconds = prevTimer.seconds + 1;
        if (seconds === 60) {
          return { minutes: prevTimer.minutes + 1, seconds: 0 };
        }
        return { ...prevTimer, seconds };
      });
    }, 1000);
    setTimerInterval(interval);
  };

  // Función para detener el temporizador
  const stopTimer = () => {
    clearInterval(timerInterval);
  };

  // Función para manejar el cambio de selección en el Select de la cámara
  const handleVideoDeviceChange = async (deviceId) => {
    try {
      if (deviceId) {
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId },
        });

        // Detener el stream anterior si existe
        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }

        // Actualizar el estado con el nuevo stream
        setStream(newStream);
        setVideoPreviewError(false);
      } else {
        setVideoPreviewError(true);
      }
    } catch (error) {
      console.error("Error al cambiar la fuente de video:", error);
      setVideoPreviewError(true);
    }
  };

  // Función para manejar el cambio de selección en el Select de audio
  const handleAudioDeviceChange = async (deviceId) => {
    try {
      if (deviceId) {
        const newStream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId },
        });

        // Detener el stream de audio anterior si existe
        if (stream) {
          const audioTracks = stream.getAudioTracks();
          if (audioTracks.length > 0) {
            audioTracks[0].stop();
          }
        }

        stream.addTrack(newStream.getAudioTracks()[0]);

        setAudioPreviewError(false);
      } else {
        setAudioPreviewError(true);
      }
    } catch (error) {
      console.error("Error al cambiar la fuente de audio:", error);
      setAudioPreviewError(true);
    }
  };
  console.log(audioPreviewError);
  // Función para habilitar o deshabilitar el video de la cámara
  const toggleVideo = async () => {
    setIsVideoEnabled(!isVideoEnabled);
    if (stream) {
      const videoTracks = stream.getVideoTracks();
      if (videoTracks.length > 0) {
        // Si se deshabilita el video, detén la vista previa de la cámara web
        if (!isVideoEnabled) {
          videoRef.current.srcObject = null;
        }

        // Habilitar o deshabilitar el track de video
        videoTracks[0].enabled = isVideoEnabled;

        // Si se habilita el video, actualiza la vista previa de la cámara web
        if (isVideoEnabled) {
          try {
            // Obtener un nuevo stream con el track de video habilitado
            const newVideoStream = await navigator.mediaDevices.getUserMedia({
              video: { deviceId: selectedVideoDevice },
            });

            if (stream) {
              // Agregar el nuevo track de video al stream existente
              stream.addTrack(newVideoStream.getVideoTracks()[0]);

              // Actualizar la vista previa de la cámara web
              videoRef.current.srcObject = stream;
            }
          } catch (error) {
            console.error("Error al habilitar la cámara:", error);
          }
        }
      }
    }
  };

  // Función para habilitar o deshabilitar el audio del micrófono
  const toggleAudio = async () => {
    setIsAudioEnabled(!isAudioEnabled);

    if (stream) {
      const audioTracks = stream.getAudioTracks();

      // Detiene las pistas de audio existentes
      audioTracks.forEach((track) => {
        track.stop();
        stream.removeTrack(track);
      });

      // Si isAudioEnabled es true, agrega una nueva pista de audio
      if (isAudioEnabled) {
        try {
          const newAudioStream = await navigator.mediaDevices.getUserMedia({
            audio: { deviceId: selectedAudioDevice },
          });

          // Agrega la nueva pista de audio al stream existente
          if (newAudioStream && newAudioStream.getAudioTracks().length > 0) {
            stream.addTrack(newAudioStream.getAudioTracks()[0]);
          }

          setAudioPreviewError(false);
        } catch (error) {
          console.error("Error al cambiar la fuente de audio:", error);
          setAudioPreviewError(true);
        }
      }
    }
  };

  const handleCloseModal = () => {
    if (timerInterval) {
      // Detener el temporizador y reiniciarlo a cero
      clearInterval(timerInterval);
      setTimer({ minutes: 0, seconds: 0 });
    }

    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      setStream(null);
    }

    setIsRecording(false);
    setVideoPreviewError(false);
    setAudioPreviewError(false);
    setIsVideoEnabled(true);
    setIsAudioEnabled(true);
    setIsPermissionGranted(false);

    onClose();
  };

  const requestPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      if (stream) {
        setIsPermissionGranted(true);
      }

      // Actualizar la lista de dispositivos después de conceder los permisos
      navigator.mediaDevices.enumerateDevices().then((deviceList) => {
        const videoDevices = deviceList.filter(
          (device) => device.kind === "videoinput"
        );
        const audioDevices = deviceList.filter(
          (device) => device.kind === "audioinput"
        );
        setDevices({ videoDevices, audioDevices });

        // Determinar el dispositivo por defecto (primero de la lista)
        const defaultVideoDevice =
          videoDevices.length > 0 ? videoDevices[0].deviceId : "";
        const defaultAudioDevice =
          audioDevices.length > 0 ? audioDevices[0].deviceId : "";

        setSelectedVideoDevice(defaultVideoDevice);
        setSelectedAudioDevice(defaultAudioDevice);
      });
    } catch (error) {
      console.error("Error requesting permissions:", error);
    }
  };

  useEffect(() => {
    const startStream = async () => {
      try {
        if (isOpen && selectedVideoDevice) {
          const constraints = {
            video: { deviceId: selectedVideoDevice },
            audio: false,
          };
          const newStream = await navigator.mediaDevices.getUserMedia(
            constraints
          );

          setStream(newStream);

          if (videoRef.current) {
            videoRef.current.srcObject = newStream;
          }
        }
      } catch (error) {
        console.error("Error al iniciar el stream:", error);
      }
    };

    startStream();
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !isPermissionGranted) {
      requestPermissions();
    }
  }, [isOpen, isPermissionGranted]);

  useEffect(() => {
    const startStream = async () => {
      try {
        if (selectedVideoDevice) {
          const constraints = {
            video: { deviceId: selectedVideoDevice },
            audio: false, // Configura el audio como falso al iniciar el flujo de video
          };
          const newStream = await navigator.mediaDevices.getUserMedia(
            constraints
          );

          setStream(newStream);

          if (videoRef.current) {
            videoRef.current.srcObject = newStream;
          }
        }
      } catch (error) {
        console.error("Error al iniciar el stream:", error);
      }
    };

    startStream();
  }, [selectedVideoDevice, selectedAudioDevice]);

  return (
    <>
      <Button padding={8} onClick={onOpen} boxShadow="md">
        <Icon as={FaFileVideo} mr={2} boxSize={5} />
        Crear archivo
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        size="2xl"
        onClose={() => {
          if (!isRecording) {
            handleCloseModal();
          } else {
            alert("Por favor detén la grabación antes de cerrar la ventana.");
          }
        }}
      >
        <ModalOverlay />
        {isPermissionGranted && (
          <ModalContent>
            <ModalHeader>Grabar un nuevo archivo</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box position="relative" width="100%">
                <Box
                  alignItems="center"
                  bg="gray.600"
                  borderRadius={8}
                  display="flex"
                  height="500px"
                  justifyContent="center"
                  overflow='hidden'
                  position="relative"
                  width="100%"
                >
                  {videoPreviewError || audioPreviewError ? (
                    <div>Error: Selecciona una fuente de video o audio válida</div>
                  ) : (
                    stream && (
                        <video ref={videoRef} autoPlay playsInline muted style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                    )
                  )}

                  <VideoCreatorOverlay
                  isAudioEnabled={isAudioEnabled}
                  isRecording={isRecording}
                  isVideoEnabled={isVideoEnabled}
                  startRecording={startRecording}
                  stopRecording={stopRecording}
                  timer={timer}
                  toggleAudio={toggleAudio}
                  toggleVideo={toggleVideo}
                  />
                </Box>

                <Flex justify="space-around" mt={4} mb={2}>
                  <Select
                    isDisabled={isRecording}
                    value={selectedVideoDevice}
                    width="40%"
                    onChange={(e) => {
                      const selectedDeviceId = e.target.value;
                      setSelectedVideoDevice(selectedDeviceId);
                      handleVideoDeviceChange(selectedDeviceId);
                    }}
                  >
                    {devices?.videoDevices?.map((device) => (
                      <option key={device.deviceId} value={device.deviceId}>
                        {device.label}
                      </option>
                    ))}
                  </Select>

                  <Select
                    isDisabled={isRecording}
                    value={selectedAudioDevice}
                    width="40%"
                    onChange={(e) => {
                      const selectedDeviceId = e.target.value;
                      setSelectedAudioDevice(selectedDeviceId);
                      handleAudioDeviceChange(selectedDeviceId);
                    }}
                  >
                    {devices?.audioDevices?.map((device) => (
                      <option key={device.deviceId} value={device.deviceId}>
                        {device.label}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </Box>
            </ModalBody>
          </ModalContent>
        )}
      </Modal>
    </>
  );
};

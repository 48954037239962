/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Box, Container, ScaleFade, SimpleGrid } from "@chakra-ui/react";
import {
  FaHospitalUser,
  FaStethoscope,
  FaUserAlt,
  FaClipboardList,
} from "react-icons/fa";

import { StoreContext } from "../context/store/StoreContext";
import { useExamenes } from "../hooks/useExamenes";
import { StatsCard } from "../components/StatsCard";
import { RenderLoading } from "../components/RenderAlert";

const StatsScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    pacientes,
    cuentas,
    professionals,
    getsPatients,
    getsOrganizations,
    getsProfessionals,
  } = useContext(StoreContext);
  const { examenes } = useExamenes();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getsPatients();
      await getsOrganizations();
      await getsProfessionals();
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            bg={"white"}
            rounded={"md"}
            shadow={"md"}
            py={10}
            maxW={"full"}
          >
            <Box maxW="7xl" mx={"auto"} px={{ base: 2, sm: 12, md: 17 }}>
              <SimpleGrid
                columns={{ base: 2, sm: 3, lg: 4 }}
                spacing={{ base: 5, lg: 8 }}
              >
                <StatsCard
                  title={"Cuentas"}
                  stat={cuentas.length}
                  icon={<FaHospitalUser size={"3em"} />}
                />
                <StatsCard
                  title={"Profesionales"}
                  stat={professionals.length}
                  icon={<FaStethoscope size={"3em"} />}
                />
                <StatsCard
                  title={"Pacientes"}
                  stat={pacientes.length}
                  icon={<FaUserAlt size={"3em"} />}
                />
                <StatsCard
                  title={"Exámenes"}
                  stat={examenes.length}
                  icon={<FaClipboardList size={"3em"} />}
                />
              </SimpleGrid>
            </Box>
          </Container>
        </ScaleFade>
      )}
    </>
  );
};

export default StatsScreen;

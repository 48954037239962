import React, { useContext, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { FaExternalLinkAlt, FaLock, FaShare } from "react-icons/fa";

import { StoreContext } from "../../context/store/StoreContext";
import { ButtonWithIcon } from "../CustomButtons";

export const ManageFeedbackModal = ({ ticket }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { feedback, getFeedback, updateFeedback } = useContext(StoreContext);

  const [isFetching, setIsFetching] = useState(false);

  const handleOpen = () => {
    setIsFetching(true);
    getFeedback(ticket.id);
    onOpen();
    setIsFetching(false);
  };

  const openImageInNewTab = () => {
    const newWindow = window.open();
    newWindow.document.write(
      `<html><head><title>Image</title></head><body style="display: flex; justify-content: center; align-items: center; margin: 0;"><img src="${feedback.url}" alt="Image" style="max-height: 100%; max-width: 100%; object-fit: contain;"></body></html>`
    );
  };

  const handleCompleteFeedback = async () => {
    setIsFetching(true);
    await updateFeedback(ticket.id);
    onClose();
    setIsFetching(false);
  };

  return (
    <>
      <Tooltip label="Gestionar ticket">
        <IconButton
          key={ticket.id}
          data={ticket}
          variant="ghost"
          aria-label="manage feedback"
          icon={<FaShare />}
          size={"sm"}
          onClick={handleOpen}
        />
      </Tooltip>

      <Modal
        isOpen={isOpen}
        isCentered
        size={"4xl"}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Stack
              direction={{ base: "column", md: "row" }}
              rounded={"md"}
              shadow={"md"}
              borderWidth={2}
              p={2}
            >
              <Box position={"relative"} width={"fit-content"}>
                <Image
                  boxSize={"md"}
                  rounded={"md"}
                  shadow={"md"}
                  src={feedback.url}
                  layout={"fill"}
                />
                <IconButton
                  position="absolute"
                  bottom={2}
                  right={2}
                  colorScheme="gray"
                  aria-label="Open image in new tab"
                  icon={<FaExternalLinkAlt />}
                  size="sm"
                  onClick={openImageInNewTab}
                />
              </Box>

              <Flex w={"full"} direction={"column"} maxWidth={"full"}>
                <Box maxWidth={"full"}>
                  <Text
                    color={"green.500"}
                    textTransform={"uppercase"}
                    fontWeight={800}
                    fontSize={"sm"}
                    letterSpacing={1.1}
                  >
                    {`Ticket #${feedback.count}`}
                  </Text>
                  <Heading
                    color={"gray.700"}
                    fontSize={"2xl"}
                    fontFamily={"body"}
                  >
                    {feedback.titulo}
                  </Heading>
                  <Text color={"gray.500"}>{feedback.comentario}</Text>
                </Box>

                <Box width={"full"}>
                  <Divider height={1} borderColor={"gray.400"} />

                  <Stack mt={2} direction={"row"} spacing={4} align={"center"}>
                    <Avatar
                      alt={feedback.autor}
                      bg="cyan.400"
                      name={feedback.autor}
                      size="sm"
                    />

                    <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                      <Text fontWeight={600}>{feedback.autor}</Text>
                      <Text color={"gray.500"}>{feedback.createdAt}</Text>
                    </Stack>
                  </Stack>
                </Box>
              </Flex>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonWithIcon
              fn={handleCompleteFeedback}
              titleButton={"Finalizar ticket"}
              titleIcon={FaLock}
              isLoading={isFetching}
            />
            <Button ml={2} onClick={onClose} colorScheme="gray">
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

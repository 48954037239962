import React from "react";
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export const TableAbmaData = ({ stats }) => {
  const capitalizeFirst = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return null;
    }
  };

  return (
    <Flex bg='#fff' height={"100%"} borderRadius="16px" borderWidth={1} boxShadow="md">
      {stats.length ? (
        <TableContainer width="full">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Parámetro</Th>
                <Th>Valor</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object?.entries(stats[0]).map((value, index) => (
                <Tr key={index}>
                  <Td>{capitalizeFirst(value[0])}</Td>
                  <Td>{value[1].toFixed(2)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : null}
    </Flex>
  );
};

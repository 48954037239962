import React from "react";
import {
  Flex,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,  
} from "@chakra-ui/react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { ManageFeedbackModal } from "../Modals/ManageFeedbackModal";

export const TablaFeedbacks = ({ feedbacks }) => {
  return (
    <TableContainer borderRadius={10} shadow={"md"}>
      <Table variant="simple" bg={"white"}>
        <Thead bg="cyan.400">
          <Tr>
            <Th># Ticket</Th>
            <Th>titulo</Th>
            <Th>autor</Th>
            <Th>estado</Th>
            <Th>acciones</Th>
          </Tr>
        </Thead>
        <Tbody>
          {feedbacks.map((ticket) => (
            <Tr key={ticket.id}>
              <Td>{ticket.count}</Td>
              <Td>{ticket.titulo}</Td>
              <Td>{ticket.autor}</Td>
              <Td>
                <Flex align={"center"}>
                  {ticket.estado === "Sin atender" ? (
                    <>
                      <Icon
                        as={FaExclamationCircle}
                        color={"yellow.300"}
                        mr={2}
                      />
                      <Text>Sin atender</Text>
                    </>
                  ) : (
                    <>
                      <Icon as={FaCheckCircle} color={"green.300"} mr={2} />
                      <Text>Finalizado</Text>
                    </>
                  )}
                </Flex>
              </Td>
              <Td>
                <ManageFeedbackModal ticket={ticket} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

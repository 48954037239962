import React  from 'react';
import { Box } from "@chakra-ui/react";
const MainPanel = ({children}) => {
  return (
    <Box>
      {children}
    </Box>
  );
}

export default MainPanel;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Input,
  InputGroup,
  InputRightElement,
  ScaleFade,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { TablaUsuarios } from "../components/Tablas/TablaUsuarios";
import AuthContext from "../context/auth/AuthContext";
import { StoreContext } from "../context/store/StoreContext";
import { RenderLoading } from "../components/RenderAlert";
import { CrearUsuario } from "../components/Ui";

const UsersScreen = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { usuario, rol } = useContext(AuthContext);
  const { getsProsByOrganization, getsProfessionals, professionals } =
    useContext(StoreContext);

  const inputBg = useColorModeValue("white", "navy.800");

  const usuariosFiltrados = () => {
    if (search.length === 0) return professionals;

    // Si hay algo en la barra de busqueda
    const filtrados = professionals.filter((professional) => {
      return (
        professional.nombre.toLowerCase().includes(search.toLowerCase()) ||
        professional.rut.toString().includes(search)
      );
    });
    return filtrados;
  };

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        if (rol.nombre === "Master") {
          await getsProfessionals();
          setIsLoading(false);
          return;
        }
        await getsProsByOrganization(usuario.id_organizacion);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container
            maxW={"full"}
            paddingLeft={{ base: 4, xl: 0 }}
            paddingRight={4}
          >
            <Box display="flex" justifyContent="flex-start">
              <InputGroup w="auto">
                <InputRightElement>
                  <FaMagnifyingGlass />
                </InputRightElement>
                <Input
                  bg={inputBg}
                  boxShadow="md"
                  htmlSize={40}
                  size="md"
                  width="auto"
                  variant="search"
                  value={search}
                  onChange={onSearchChange}
                  placeholder="Buscar usuario por nombre o rut"
                />
              </InputGroup>

              <CrearUsuario />
            </Box>

            <TablaUsuarios users={usuariosFiltrados()} />
          </Container>
        </ScaleFade>
      )}
    </>
  );
};

export default UsersScreen;

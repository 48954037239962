import React, { useContext } from "react";
import { Container } from "@chakra-ui/react";
import AuthContext from "../context/auth/AuthContext";
import { ProfileAdminContent } from "../components/Profile/ProfileAdminContent";
import { ProfileProContent } from "../components/Profile/ProfileProContent";

const ProfileScreen = () => {
  const { rol } = useContext(AuthContext);

  return (
    <Container maxW={"full"}>
      {rol.nombre === "Administrador" ? (
        <ProfileAdminContent />
      ) : (
        <ProfileProContent />
      )}
    </Container>
  );
};

export default ProfileScreen;

import React, { useState, useEffect, useContext } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Center,
} from "@chakra-ui/react";
import AuthContext from "../context/auth/AuthContext";
import Swal from "sweetalert2";

const InactivityAlert = ({ isOpen, onClose, onStayConnected, onLogout }) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={undefined}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <Center>¿Aún estás ahí?</Center>
          </AlertDialogHeader>

          <AlertDialogBody>
            <Center>
              Tu sesión está a punto de expirar debido a la inactividad.
            </Center>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme="blue" mr={3} onClick={onStayConnected}>
              Seguir conectado
            </Button>
            <Button variant="outline" onClick={onLogout}>
              Cerrar sesión
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export const SessionManager = () => {
  const [showAlert, setShowAlert] = useState(false);
  const { logoutRequest } = useContext(AuthContext);

  useEffect(() => {
    let inactivityTimer;

    const resetInactivityTimer = () => {
      if (inactivityTimer) {
        clearTimeout(inactivityTimer);
      }

      inactivityTimer = setTimeout(() => {
        setShowAlert(true);
      // }, 1 * 20 * 1000); // 20 segundos en milisegundos
      }, 3 * 60 * 1000); // 3 minutos en milisegundos
    };

    const handleUserActivity = () => {      
      if (showAlert) {        
        resetInactivityTimer();
        setShowAlert(false);
      }
    };  

    const closingAlertDialog = () => {      
      if (!showAlert) {        
        resetInactivityTimer();        
      }
    };  

    resetInactivityTimer();
    
    window.addEventListener("mousemove", closingAlertDialog);
    window.addEventListener("keydown", closingAlertDialog);        

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);      
    };
  }, [showAlert]);

  const handleStayConnected = () => {
    // Lógica para mantener al usuario conectado (renovar token, etc.)
    setShowAlert(false);
  };

  const handleLogout = () => {
    // Lógica para cerrar la sesión del usuario
    logoutRequest();
    Swal.fire({
      icon: "error",
      title: "Sesión caducada.",
      text: "Iniciar sesión nuevamente!",
    });
    setShowAlert(false);
  };

  return (    
      <InactivityAlert
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
        onStayConnected={handleStayConnected}
        onLogout={handleLogout}
      />
  );
};

import React from "react";
import { Flex, Spacer } from "@chakra-ui/react";
import { FileUploader } from "./FileUploader";
import { FileCreator } from "./FileCreator";

export const FileUploadOptionsBox = ({
  archivos,
  placeHolder,
  setArchivos,
  setVideoPreview,
  accept,
  icon,
}) => {
  return (
    <>
      <Flex align="center" justify="center" my={4}>
        <Spacer />

        <FileCreator
          archivos={archivos}
          setArchivos={setArchivos}
          setVideoPreview={setVideoPreview}
        />

        <Spacer />

        <FileUploader placeHolder={placeHolder} accept={accept} icon={icon} />

        <Spacer />
      </Flex>
    </>
  );
};

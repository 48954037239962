import React from "react";
import AppRouter from "./routers/AppRouter";
import { StoreState } from "./context/store/StoreState";
import { ExamenState } from "./context/examen/ExamenState";

const LanekApp = () => {
  return (
    <>
      <StoreState>
        <ExamenState>
          <AppRouter />
        </ExamenState>
      </StoreState>
    </>
  );
};

export default LanekApp;

import React from "react";
import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import "chartjs-adapter-moment";
import { Bubble } from "react-chartjs-2";

export const BubbleChart = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dataBubbleChart = {
    datasets: [
      {
        label: "Valores las últimas sesiones",
        data: [
          { x: "2023-04-05", y: 40, r: 10 }, // 5 de abril
          { x: "2023-06-09", y: 35, r: 15 }, // 9 de junio
          { x: "2023-11-12", y: 30, r: 10 }, // 12 de noviembre
        ],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  const optionsBubbleChart = {
    scales: {
      x: {
        type: "time",
        time: {
          parser: "YYYY-MM-DD",
          tooltipFormat: "ll",
        },
        title: {
          display: true,
          text: "Fechas",
        },
      },
      y: {
        title: {
          display: true,
          text: "Valores",
        },
      },
    },
  };

  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent isCentered>
          <ModalHeader>Ultimos registros</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Bubble data={dataBubbleChart} options={optionsBubbleChart} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {/* <Button variant="ghost">Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Center>
        <Button onClick={onOpen}>Ver detalle</Button>
      </Center>
    </>
  );
};
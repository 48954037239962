/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useMediaQuery,
  useToast,
  TableContainer,
  Container,
} from "@chakra-ui/react";
import {
  FaAngleLeft,
  FaAngleRight,
  FaClipboard,
  FaClipboardList,
  FaDownload,
  FaTrash,
  FaEllipsisVertical,
  FaFileMedical,
} from "react-icons/fa6";
import { ExamenContext } from "../../context/examen/ExamenContext";
import { lanekApi } from "../../api/lanekApi";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmar Eliminación</ModalHeader>
        <ModalCloseButton />
        <ModalBody>¿Estás seguro de que deseas eliminar este examen?</ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Sí
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const TablaExamenes = ({ idpaciente, paciente, setOpenDetalle }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [examDeletingId, setExamDeletingId] = useState(null);
  const [examToDelete, setExamToDelete] = useState(null);
  const [sortConfig, setSortConfig] = useState({ field: "", direction: "" });

  const navigate = useNavigate();

  const toast = useToast();
  const [isLargerScreen] = useMediaQuery("(min-width: 990px)");

  const { rol } = useAuth();
  const { getPatientExams, examenes, deleteExam } = useContext(ExamenContext);

  const handleSort = (field) => {
    if (sortConfig.field === field) {
      setSortConfig((prevConfig) => ({
        field,
        direction: prevConfig.direction === "asc" ? "desc" : "asc",
      }));
    } else {
      setSortConfig({ field, direction: "asc" });
    }
  };

  const sortedExams = [...examenes].sort((a, b) => {
    const { field, direction } = sortConfig;
    if (a[field] < b[field]) return direction === "asc" ? -1 : 1;
    if (a[field] > b[field]) return direction === "asc" ? 1 : -1;
    return 0;
  });

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedExams?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedExams?.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPagination = () => {
    const adjacentPages = 2;
    const totalAdjacentPages = adjacentPages * 2 + 1;

    let startPage = Math.max(1, currentPage - adjacentPages);
    let endPage = Math.min(totalPages, startPage + totalAdjacentPages - 1);

    if (endPage - startPage < totalAdjacentPages - 1) {
      startPage = Math.max(1, endPage - totalAdjacentPages + 1);
    }

    return (
      <>
        <IconButton
          aria-label="pagina anterior"
          borderRadius="full"
          colorScheme="twitter"
          icon={<Icon as={FaAngleLeft} />}
          isDisabled={currentPage === 1}
          size={{ base: "sm", md: "md" }}
          variant="ghost"
          onClick={() =>
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
          }
        />

        {pageNumbers.slice(startPage - 1, endPage).map((pageNumber) => (
          <Button
            borderRadius="full"
            colorScheme="twitter"
            key={pageNumber}
            mx={1}
            size={{ base: "sm", md: "md" }}
            variant={pageNumber === currentPage ? "solid" : "outline"}
            onClick={() => goToPage(pageNumber)}
            style={{
              fontWeight: pageNumber === currentPage ? "bold" : "normal",
            }}
          >
            {pageNumber}
          </Button>
        ))}

        <IconButton
          aria-label="pagina siguiente"
          borderRadius="full"
          colorScheme="twitter"
          icon={<Icon as={FaAngleRight} />}
          isDisabled={currentPage === totalPages}
          size={{ base: "sm", md: "md" }}
          variant="ghost"
          onClick={() =>
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
          }
        />
      </>
    );
  };

  // Cambiar a una página específica
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(e.target.value, 10));
  };

  const handleDownloadFiles = async (idexamen) => {
    try {
      const response = await lanekApi.get(
        `/examen/download-files/${idexamen}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${paciente.nombre}_examen_${idexamen}.zip`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    setExamToDelete(id);
    setIsDeleteModalOpen(true);
  };

  const handleClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);

      toast({
        description: "Se ha copiado el ID al portapapeles.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPatientExams(idpaciente);
    setSortConfig({ field: "createdAt", direction: "desc" }); // Establecer orden inicial
  }, [idpaciente]);

  return (
    <>
      <Flex align="center" mb={4} mt={10}>
        <Select
          onChange={handleItemsPerPageChange}
          value={itemsPerPage}
          bg="white"
          boxShadow={"md"}
          width="80px"
          mr={2}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </Select>
        <Text>Elementos por página</Text>
      </Flex>

      <Container maxW="calc(100vw - 20px)" p={0} ml={0} mr={0}>
        <Flex
          align="center"
          bg="#fff"
          borderTopRadius="16px"
          boxShadow={"md"}
          justify="space-between"
          padding={4}
        >
          <Text fontSize="lg" fontWeight="bold" textTransform="uppercase">
            Resumen exámenes
          </Text>
          <Button
            leftIcon={<FaFileMedical />}
            colorScheme="twitter"
            size={{ base: "sm", md: "md" }}
            borderRadius="md"
            onClick={() => navigate(`/paciente/${idpaciente}/nuevo_examen`)}
          >
            Nuevo examen
          </Button>
        </Flex>

        <TableContainer
          bg="#fff"
          boxShadow="md"
          borderBottomRadius="16px"
          padding={2}
        >
          <Table variant="simple">
            <TableCaption>
              <Flex justify="space-between" align="center">
                <Box>{renderPagination()}</Box>
              </Flex>
            </TableCaption>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th width="fit-content">Tipo</Th>
                <Th onClick={() => handleSort("estado_examen")}>Estado</Th>
                <Th onClick={() => handleSort("profesional")}>Tomado por</Th>
                <Th onClick={() => handleSort("createdAt")}>Fecha</Th>
                <Th>Acciones</Th>
              </Tr>
            </Thead>

            <Tbody>
              {currentItems.map((examen, index) => {
                const [producto, categoria] = examen.descripcion.split(" / ");
                return (
                  <Tr key={examen.id}>
                    <Td>{index + 1}</Td>

                    <Td>
                      <Flex direction="column">
                        <Text fontSize="md" fontWeight="normal">
                          {producto}
                        </Text>
                        {producto === "AVM" && (
                          <Text
                            fontSize="sm"
                            color="gray.400"
                            fontWeight="normal"
                          >
                            {categoria}
                          </Text>
                        )}
                      </Flex>
                    </Td>
                    <Td>
                      {
                        <Badge
                          bg={
                            examen.estado_examen === "Procesado"
                              ? "green.400"
                              : examen.estado_examen === "Procesando"
                              ? "gray.400"
                              : "red.400"
                          }
                          color="white"
                          fontSize={{ base: ".5rem", md: ".7rem" }}
                          p="2px 4px"
                          borderRadius="8px"
                        >
                          {examen.estado_examen}
                        </Badge>
                      }
                    </Td>
                    <Td>{examen.autor}</Td>
                    <Td>{examen.fecha_creacion}</Td>
                    <Td>
                      {isLargerScreen ? (
                        <>
                          {examen.estado_examen === "Procesado" && (
                            <>
                              <Tooltip hasArrow label="Ver resultados en línea">
                                <IconButton
                                  fontSize="1.2rem"
                                  variant={"ghost"}
                                  aria-label="Ver en linea"
                                  icon={<FaClipboardList />}
                                  onClick={() => setOpenDetalle(examen.id)}
                                />
                              </Tooltip>

                              <Tooltip
                                hasArrow
                                label="Descargar archivos adjuntos"
                              >
                                <IconButton
                                  fontSize="1.2rem"
                                  variant={"ghost"}
                                  aria-label="Descarga archivos"
                                  icon={<FaDownload />}
                                  onClick={() => handleDownloadFiles(examen.id)}
                                />
                              </Tooltip>
                            </>
                          )}
                          {examDeletingId === examen.id ? (
                            <Spinner size={"sm"} />
                          ) : (
                            <Tooltip hasArrow label="Eliminar examen">
                              <IconButton
                                fontSize="1.2rem"
                                variant={"ghost"}
                                aria-label="Eliminar examen"
                                icon={<FaTrash />}
                                onClick={() => handleDelete(examen.id)}
                              />
                            </Tooltip>
                          )}
                          {rol?.nombre && (
                            <Tooltip hasArrow label="Copiar ID">
                              <IconButton
                                fontSize="1.2rem"
                                variant={"ghost"}
                                aria-label="Copiar ID"
                                icon={<FaClipboard />}
                                onClick={() => handleClipboard(examen.id)}
                              />
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            aria-label="Acciones"
                            icon={<FaEllipsisVertical />}
                            variant="ghost"
                          />
                          <MenuList>
                            {examen.estado_examen === "Procesado" && (
                              <>
                                <MenuItem
                                  icon={<FaClipboardList />}
                                  onClick={() => setOpenDetalle(examen.id)}
                                >
                                  Ver en línea
                                </MenuItem>
                                <MenuItem
                                  icon={<FaDownload />}
                                  onClick={() => handleDownloadFiles(examen.id)}
                                >
                                  Descargar archivos
                                </MenuItem>
                              </>
                            )}
                            <MenuItem
                              icon={<FaTrash />}
                              onClick={() => handleDelete(examen.id)}
                            >
                              Eliminar examen
                            </MenuItem>

                            <MenuItem
                              icon={<FaClipboard />}
                              onClick={() => handleClipboard(examen.id)}
                            >
                              Copiar ID
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setExamToDelete(null);
        }}
        onConfirm={async () => {
          if (examToDelete) {
            setExamDeletingId(examToDelete);
            await deleteExam(examToDelete);
            await getPatientExams(idpaciente);
            setExamDeletingId(null);
          }
        }}
      />
    </>
  );
};

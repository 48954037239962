import React, { useContext, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { UsuarioForm } from "../Forms/UsuarioForm";
import { StoreContext } from "../../context/store/StoreContext";
import { formatearRut } from "../../utils/formatearRut";
import { FaUserPen } from "react-icons/fa6";

export const EditarUsuario = ({ usuario }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateProfessional } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [rut, setRut] = useState(usuario.rut);

  const handleRutChange = (event) => {
    const formattedRut = formatearRut(event.target.value);
    setRut(formattedRut);
  };

  const handleEditarUsuario = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const dataCuenta = { ...values, rut: rut };
    await updateProfessional(dataCuenta);
    setSubmitting(false);
    setIsLoading(false);
    onClose();
  };

  return (
    <>
      <Stack direction={["column", "row"]}>
        <Button leftIcon={<FaUserPen />} colorScheme="twitter" onClick={onOpen}>
          Editar
        </Button>
      </Stack>

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editando organización</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UsuarioForm
              handleRutChange={handleRutChange}
              initialValues={usuario}
              isLoading={isLoading}
              onSubmit={handleEditarUsuario}
              rut={rut}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Stack,
  Icon,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { HSeparator } from "../Separator/Separator";
import NavItem from "./NavItem";
import { FaBars } from "react-icons/fa6";

//* sidebar pantallas grandes
const Sidebar = ({ routes }) => {
  const mainPanel = React.useRef();
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/inicio");
  };

  let variantChange = "0.2s linear";

  const sidebarRoutes = routes.filter(
    (route) =>
      ![
        "/perfil",
        "/pacientes/:idpaciente",
        "/paciente/:idpaciente/nuevo_examen",
        "/paciente/:idpaciente/nuevo_examen/:producto/:idexamen",
      ].includes(route.path)
  );

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key} icon={prop.icon} navigateTo={prop.path}>
          {prop.name}
        </NavItem>
      );
    });
  };

  var links = <>{createLinks(sidebarRoutes)}</>;
  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarRadius = "16px";
  let sidebarMargins = "0px";
  var brand = (
    <Box pt={"10px"}>
      <Box
        className="bg__login--img"
        onClick={() => {
          handleGoHome();
        }}
        _hover={{ cursor: "pointer" }}
      />
      <HSeparator my="16px" />
    </Box>
  );

  // SIDEBAR
  return (
    <Box ref={mainPanel}>
      <Box display={{ base: "none", xl: "block" }} position="fixed">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w="260px"
          maxW="260px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m={sidebarMargins}
          filter="drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.05))"
          borderRadius={sidebarRadius}
        >
          <Box>{brand}</Box>
          <Stack direction="column" mb="40px">
            <Box>{links}</Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

//* sidebar pantallas chicas
export function SidebarResponsive({ logo, routes, colorMode }) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const mainPanel = React.useRef();
  const btnRef = React.useRef();

  const sidebarRoutes = routes.filter(
    (route) =>
      ![
        "/perfil",
        "/pacientes/:idpaciente",
        "/paciente/:idpaciente/nuevo_examen",
        "/paciente/:idpaciente/nuevo_examen/:producto/:idexamen",
      ].includes(route.path)
  );

  const handleGoHome = () => {
    navigate("/");
  };

  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem
          key={key}
          icon={prop.icon}
          navigateTo={prop.path}
          onClose={onClose}
        >
          {prop.name}
        </NavItem>
      );
    });
  };

  var links = <>{createLinks(sidebarRoutes)}</>;

  //  BRAND
  var brand = (
    <Box pt={"10px"}>
      <Box
        className="bg__login--img"
        onClick={() => {
          handleGoHome();
          onClose();
        }}
        _hover={{ cursor: "pointer" }}
      />
      <HSeparator my="16px" />
    </Box>
  );

  // SIDEBAR
  return (
    <Flex
      display={{ base: "flex", xl: "none" }}
      ref={mainPanel}
      alignItems="center"
    >
      <Icon
        as={FaBars}
        color={"#333"}
        w="18px"
        h="18px"
        ref={btnRef}
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={"left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w="250px"
          maxW="250px"
          ms={{
            base: "16px",
          }}
          my={{
            base: "16px",
          }}
          borderRadius="16px"
          bg={sidebarBackgroundColor}
        >
          <DrawerBody maxW="250px" px="1rem">
            <Box maxW="100%" h="100%">
              <Box>{brand}</Box>
              <Stack direction="column">
                <Box>{links}</Box>
              </Stack>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

export default Sidebar;

import React, { useContext, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FaUserPlus } from "react-icons/fa6";
import { UsuarioForm } from "../Forms/UsuarioForm";
import { StoreContext } from "../../context/store/StoreContext";
import { formatearRut } from "../../utils/formatearRut";

const initialValues = {
  nombre: "",
  apellidos: "",
  rut: "",
  email: "",
  password: "",
  telefono: "",
};

export const CrearUsuario = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createProfessional } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [rut, setRut] = useState("");

  const handleCreate = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const dataFormulario = {
      ...values,
      rut: rut,
      password: rut.split("-")[0].replace(/\./g, ""),
      passwordForEmail: rut.split("-")[0].replace(/\./g, ""),
    };
    await createProfessional(dataFormulario);
    setSubmitting(false);
    setIsLoading(false);
    setRut("");
    onClose();
  };

  const handleRutChange = (event) => {
    const formattedRut = formatearRut(event.target.value);
    setRut(formattedRut);
  };

  return (
    <>
      <Button
        leftIcon={<FaUserPlus />}
        colorScheme="twitter"
        ml={4}
        boxShadow={"lg"}
        onClick={onOpen}
      >
        Crear usuario
      </Button>

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nuevo Usuario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UsuarioForm
              handleRutChange={handleRutChange}
              initialValues={initialValues}
              isLoading={isLoading}
              onSubmit={handleCreate}
              rut={rut}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

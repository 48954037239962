import React, { useContext } from "react";
import {
  Center,
  Checkbox,
  FormControl,
  FormLabel,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { InputNormal, InputRut } from "./InputForm";
import { ButtonWithIcon } from "../CustomButtons";
import AuthContext from "../../context/auth/AuthContext";
import { FaSave } from "react-icons/fa";

export const CuentaForm = ({
  habilitado,
  handleRutChange,
  initialValues,
  isLoading,
  onSubmit,
  options,
  setOptions,
  rut,
  setHabilitado,
}) => {
  const { rol } = useContext(AuthContext);

  const validationSchema = Yup.object({
    rut: Yup.string().matches(
      /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/,
      "RUT inválido"
    ),
    nombre: Yup.string().required("Campo obligatorio"),
    email: Yup.string().email("Correo invalido").required("Campo obligatorio"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Form>
          <InputRut
            label="RUT"
            name="rut"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleRutChange}
            value={rut}
          />
          {touched.rut && errors.rut ? <span>{errors.rut}</span> : null}

          <InputNormal
            label="Nombre"
            name="nombre"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.nombre}
          />
          {touched.nombre && errors.nombre ? <div>{errors.nombre}</div> : null}

          {rol.nombre === "Master" && (
            <Stack spacing={4} direction={"row"} justify={"flex-start"} mt={4}>
              <Checkbox
                isChecked={options.abma}
                onChange={() => setOptions("abma")}
              >
                ABMA
              </Checkbox>
              <Checkbox
                isChecked={options.avm}
                onChange={() => setOptions("avm")}
              >
                AVM
              </Checkbox>
              <Checkbox
                isChecked={options.video}
                onChange={() => setOptions("video")}
              >
                Video
              </Checkbox>
              <Checkbox
                isChecked={options.otro}
                onChange={() => setOptions("otro")}
              >
                Otro
              </Checkbox>
            </Stack>
          )}

          <InputNormal
            label="Correo electrónico"
            name="email"
            type="email"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.email}
          />
          {touched.email && errors.email ? <div>{errors.email}</div> : null}

          <InputNormal
            label="Teléfono"
            name="telefono"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.telefono}
          />

          <InputNormal
            label="Dirección"
            name="direccion"
            type="text"
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={values.direccion}
          />

          {rol.nombre === "Master" && (
            <>
              <InputNormal
                label="Fecha de pago"
                name="fecha_pago"
                type="date"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.fecha_pago}
              />

              <FormControl display="flex" alignItems="center" mt={2}>
                <FormLabel htmlFor="isHabilitado" mb="0">
                  Habilitado
                </FormLabel>
                <Switch
                  id="isHabilitado"
                  isChecked={habilitado}
                  onChange={() => setHabilitado(!habilitado)}
                />
              </FormControl>
            </>
          )}

          <Center mt={5}>
            <ButtonWithIcon
              fn={handleSubmit}
              titleButton="Guardar"
              titleIcon={FaSave}
              isLoading={isLoading}
            />
          </Center>
        </Form>
      )}
    </Formik>
  );
};

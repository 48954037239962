/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import { ChatButton } from "../components/ChatButton";
// import { ChatBot } from "../components/Chatbot";
import {
  Card,
  Container,
  Flex,
  Icon,
  ScaleFade,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaFileLines, FaUserGroup } from "react-icons/fa6";
import { RenderLoading } from "../components/RenderAlert";
import { SessionManager } from "../components/SessionManager";
import IconBox from "../components/Icons/IconBox";
import { useAuth } from "../hooks/useAuth";
import { useStore } from "../hooks/useStore";

const SummaryScreen = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { usuario } = useAuth();
  const { summary, getSummariesPro } = useStore();

  const { dataExams, dataPatients } = summary;

  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const textColor = useColorModeValue("gray.700", "white");

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        setIsLoading(true);
        // if (usuario.rol.nombre === "Profesional") {
        if (usuario.rol.nombre) {
          await getSummariesPro(usuario.id_profesional);
          setIsLoading(false);
          return;
        } else {
          console.log("no hay datos asociados");
        }
        // await getsPatientsByOrganization(usuario.id_organizacion);
        // setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSummary();
  }, []);


  const percentageVariation =
    dataExams?.variations[dataExams?.variations.length - 1]?.variation;

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <>
          <SessionManager />

          <ScaleFade initialScale={0.9} in={true}>
            <Container
              maxW={"100%"}
              paddingLeft={{ base: 4, xl: 0 }}
              paddingRight={4}
            >
              <SimpleGrid
                columns={{ base: 2, md: 3, xl: 4 }}
                spacing="24px"
                mb="20px"
              >
                <Card
                  minH="125px"
                  padding={4}
                  borderRadius="16px"
                  overflow="hidden"
                  boxShadow={"md"}
                >
                  <Flex direction="column">
                    <Flex
                      flexDirection="row"
                      align="center"
                      justify="center"
                      w="100%"
                      mb="25px"
                    >
                      <Stat me="auto">
                        <StatLabel
                          fontSize="xs"
                          color="gray.400"
                          fontWeight="bold"
                          textTransform="uppercase"
                        >
                          Pacientes creados
                        </StatLabel>
                          <Flex>
                            <StatNumber
                              fontSize="lg"
                              color={textColor}
                              fontWeight="bold"
                            >
                              {dataPatients?.totalPatients
                                ? dataPatients.totalPatients
                                : "Sin datos"}
                            </StatNumber>
                          </Flex>
                      </Stat>
                      <IconBox
                        borderRadius="50%"
                        as="box"
                        h={{ base: "35px", md: "45px" }}
                        w={{ base: "35px", md: "45px" }}
                        bg={iconBlue}
                        p={{ base: 4, md: 2 }}
                      >
                        <Icon
                          as={FaUserGroup}
                          boxSize={{ base: 8, md: 6 }}
                          color={"white"}
                        />
                      </IconBox>
                    </Flex>
                    <Text color="gray.400" fontSize="sm">
                      <Text as="span" color="green.400" fontWeight="bold">
                        Por calcular{" "}
                      </Text>
                      Desde el último mes
                    </Text>
                  </Flex>
                </Card>

                <Card
                  minH="125px"
                  padding={4}
                  borderRadius="16px"
                  overflow="hidden"
                  boxShadow={"md"}
                >
                  <Flex direction="column">
                    <Flex
                      flexDirection="row"
                      align="center"
                      justify="center"
                      w="100%"
                      mb="25px"
                    >
                      <Stat me="auto">
                        <StatLabel
                          fontSize="xs"
                          color="gray.400"
                          fontWeight="bold"
                          textTransform="uppercase"
                        >
                          Exámenes realizados
                        </StatLabel>
                        <Flex>
                          <StatNumber
                            fontSize="lg"
                            color={textColor}
                            fontWeight="bold"
                          >
                            {dataExams?.total ? dataExams.total : "Sin datos"}
                          </StatNumber>
                        </Flex>
                      </Stat>
                      <IconBox
                        borderRadius="50%"
                        as="box"
                        h={"45px"}
                        w={"45px"}
                        bg={iconBlue}
                        p={{ base: 4, md: 2 }}
                      >
                        <Icon
                          as={FaFileLines}
                          boxSize={{ base: 8, md: 6 }}
                          color={"white"}
                        />
                      </IconBox>
                    </Flex>
                    <Text color="gray.400" fontSize="sm">
                      <Text
                        as="span"
                        color={
                          percentageVariation < 0
                            ? "red.400"
                            : percentageVariation > 0
                            ? "green.400"
                            : "gray.600"
                        }
                        fontWeight="bold"
                        mr={2}
                      >
                        {percentageVariation > 0
                          ? `+${percentageVariation.toFixed(1)}%`
                          : percentageVariation < 0
                          ? `${percentageVariation.toFixed(1)}%`
                          : "0%"}
                      </Text>
                      Desde el último mes
                    </Text>
                  </Flex>
                </Card>
              </SimpleGrid>
            </Container>
          </ScaleFade>
        </>
      )}
    </>
  );
};

export default SummaryScreen;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Spinner,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaAngleDown, FaArrowRightFromBracket, FaUser } from "react-icons/fa6";
import { SidebarResponsive } from "../Sidebar/Sidebar";
import { FeedbackModal } from "../Modals/FeedbackModal";
import { useAuth } from "../../hooks/useAuth";
import { useStore } from "../../hooks/useStore";

export default function HeaderLinks(props) {
  const {
    variant,
    children,
    fixed,
    scrolled,
    secondary,
    onOpen,
    routes,
    ...rest
  } = props;

  const navigate = useNavigate();
  const { usuario, rol, logoutRequest } = useAuth();
  const { getProfessional, user_photo } = useStore();

  const [isLoading, setIsLoading] = useState(false);

  let nombre_usuario;

  if (usuario?.profesional) {
    nombre_usuario = usuario.profesional.apellidos
      ? usuario.profesional.nombre + " " + usuario.profesional?.apellidos
      : usuario.profesional.nombre;
  } else if (usuario?.paciente) {
    nombre_usuario = usuario.paciente.nombre + " " + usuario.paciente.apellidos;
  }

  const handleLogout = () => {
    logoutRequest();
    navigate("/auth/login", {
      replace: true,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getProfessional(usuario.id_profesional);
      setIsLoading(false);
      return;
    };

    fetchData();
  }, []);

  return (
    <Flex
      pe={{ base: "0px", xl: "16px" }}
      paddingX={4}
      w={{ base: "100%", xl: "auto" }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {/* <SearchBar me="18px" /> */}

      <SidebarResponsive
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            <Box w="1px" h="20px" />
          </Stack>
        }
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />

      <HStack spacing={{ base: "0" }}>
        <FeedbackModal />

        <Flex align={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                {isLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <Avatar
                    size="sm"
                    bg="cyan.400"
                    name={nombre_usuario}
                    src={user_photo && user_photo}
                  />
                )}

                <VStack
                  maxW="140px"
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text as={"b"} fontSize="md" className="truncate">
                    {nombre_usuario}
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    {rol.nombre}
                  </Text>
                </VStack>

                <Box display={{ base: "none", md: "flex" }}>
                  <FaAngleDown />
                </Box>
              </HStack>
            </MenuButton>

            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem icon={<FaUser />} onClick={() => navigate("/perfil")}>
                Perfil
              </MenuItem>

              <MenuDivider />
              <MenuItem
                icon={<FaArrowRightFromBracket />}
                onClick={handleLogout}
              >
                Cerrar sesión
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
}

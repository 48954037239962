/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ExportToExcel } from "./ExportToExcel";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { RenderLoading } from "../components/RenderAlert";
import { ExamenContext } from "../context/examen/ExamenContext";

const ResearchDataScreen = () => {
  const { getAllExamsAVM, result } = useContext(ExamenContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const all_quizzes = async () => {
      try {
        setIsLoading(true);
        await getAllExamsAVM();
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    all_quizzes();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <Box>
          <Flex justify={"flex-start"} align={"flex-end"} mb={4}>
            <Stack spacing={4} direction="row" align="center">
              <ExportToExcel data={result} />
            </Stack>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default ResearchDataScreen;

/* 
  Router principal, se maneja un router privado y publico dependiendo si el usuario esta autenticado o no.
*/

import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PublicRouter from "./PublicRouter";
import PrivateRouter from "./PrivateRouter";

const Summary = lazy(() => import("../screens/SummaryScreen"));
const Patients = lazy(() => import("../screens/PatientsScreen"));
const Patient = lazy(() => import("../screens/PatientScreen"));
const Users = lazy(() => import("../screens/UsersScreen"));
const Profile = lazy(() => import("../screens/ProfileScreen"));
const ExamOptions = lazy(() => import("../screens/ExamsOptionsScreen"));
const Exams = lazy(() => import("../screens/ExamScreen"));
const Organization = lazy(() => import("../screens/OrganizationScreen"));
const Stats = lazy(() => import("../screens/StatsScreen"));
const Feedback = lazy(() => import("../screens/FeedbackScreen"));
const ResearchData = lazy(() => import("../screens/ResearchDataScreen"));
const LoginScreen = lazy(() => import("../screens/auth/LoginScreen"));

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<h2>Loading data...</h2>}>
        <Routes>
          <Route element={<PrivateRouter />}>
            <Route path="/*" element={<Summary />} />
            <Route path="/inicio" element={<Summary />} />

            <Route path="/pacientes" element={<Patients />} />
            <Route path="/pacientes/:idpaciente" element={<Patient />} />
            <Route path="/usuarios" element={<Users />} />

            <Route
              path="/paciente/:idpaciente/nuevo_examen"
              element={<ExamOptions />}
            />
            <Route
              path="/paciente/:idpaciente/nuevo_examen/:producto/:idexamen"
              element={<Exams />}
            />

            <Route path="/perfil" element={<Profile />} />

            <Route path="/cuentas" element={<Organization />} />
            <Route path="/estadisticas" element={<Stats />} />
            <Route path="/feedbacks" element={<Feedback />} />
            <Route path="/datos-investigacion" element={<ResearchData />} />
          </Route>

          <Route element={<PublicRouter />}>
            <Route path="/auth/login" element={<LoginScreen />} />
          </Route>

          <Route path="*" element={<Navigate to="/auth/login" replace />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;

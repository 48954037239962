import React from "react";
import { VStack, Button } from "@chakra-ui/react";
import { FaRocket, FaFileLines, FaLock } from "react-icons/fa6";



const ProfileSideMenu = ({ handle, selectedSection }) => {
  return (
    <VStack
      align="flex-start"
      spacing={4}
      bg="#fff"
      padding={4}
      borderRadius="16px"
      ml={{ base: 0, xl: "-20px" }}
      boxShadow="md"
      position={{base: 'relative', md:'sticky'}}
      top={{base: "0", md:"120"}}
      height="fit-content"
    >
      <MenuButton
        handle={handle}
        selected={selectedSection}
        icon={<FaRocket />}
        item="profile"
      >
        Perfil
      </MenuButton>

      <MenuButton
        handle={handle}
        selected={selectedSection}
        icon={<FaFileLines />}
        item="info"
      >
        Información Básica
      </MenuButton>

      <MenuButton
        handle={handle}
        selected={selectedSection}
        icon={<FaLock />}
        item="password"
      >
       Cambiar Contraseña
      </MenuButton>
    </VStack>
  );
};

export default ProfileSideMenu;

const MenuButton = ({ children, handle, selected, icon, item }) => {
    return (
      <Button
        color={selected === item ? "white" : "current"}
        colorScheme={selected === item ? "twitter" : "transparent"}
        leftIcon={icon}
        onClick={() => handle(item)}
      >
        {children}
      </Button>
    );
  };
export const formatearRut = (rut) => {
  rut = rut.replace(/[^\dkK]/g, ""); // elimina todos los caracteres que no sean números ni k/K
  rut = rut.replace(/^0+/, ""); // elimina los ceros iniciales
  if (rut.length < 2) {
    return rut;
  }
  let cuerpo = rut.slice(0, -1);
  let dv = rut.slice(-1).toUpperCase();
  cuerpo = cuerpo.replace(/(\d)(?=(\d{3})+$)/g, "$1.");
  return cuerpo + "-" + dv;
};

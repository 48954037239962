/* eslint-disable import/no-anonymous-default-export */
import {
  CREAR_NUEVO_PACIENTE,
  CARGAR_PACIENTE,
  CARGAR_PACIENTES,
  CARGAR_TIPO_ORGANIZACIONES,
  SET_ORGANIZACION,
  CARGAR_CUENTAS,
  CARGA_PROFESIONALES,
  SET_PROFESIONAL,
  CARGA_FEEDBACKS,
  GET_FEEDBACK,
  GET_USER_PHOTO,
  GET_SUMMARIES_DATA,
} from "./actions";

export default (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case CARGAR_PACIENTES:
      return {
        ...state,
        pacientes: payload,
      };

    case CARGAR_PACIENTE:
      return {
        ...state,
        paciente: payload,
      };

    case CREAR_NUEVO_PACIENTE:
      return {
        ...state,
        pacientes: payload,
      };

    case CARGAR_TIPO_ORGANIZACIONES:
      return {
        ...state,
        tipo_organizaciones: payload,
      };

    case SET_ORGANIZACION:
      return {
        ...state,
        organizacion: payload,
      };

    case CARGAR_CUENTAS:
      return {
        ...state,
        cuentas: payload,
      };

    case CARGA_PROFESIONALES:
      return {
        ...state,
        professionals: payload,
      };

    case SET_PROFESIONAL:
      return {
        ...state,
        profesional: payload,
      };

    case CARGA_FEEDBACKS:
      return {
        ...state,
        feedbacks: payload,
      };

    case GET_FEEDBACK:
      return {
        ...state,
        feedback: payload,
      };

      case GET_USER_PHOTO: {
        return {
          ...state,
          user_photo: payload
        }
      }

      case GET_SUMMARIES_DATA: {
        return {
          ...state,
          summary: payload
        }
      }

    default:
      return state;
  }
};

import React, { useContext } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
} from "@chakra-ui/react";
import { useForm } from "../../hooks/useForm";
import AuthContext from "../../context/auth/AuthContext";
import { ButtonWithSpinner } from "../../components/CustomButtons";
import { useNavigate } from "react-router-dom";

const LoginScreen = () => {
  const navigate = useNavigate();
  const { startLogin, isLoginLoading, startLoading } = useContext(AuthContext);

  const [formValues, handleInputChange] = useForm({
    email: "",
    password: "",
  });

  const { email, password } = formValues;

  const handleLogin = (e) => {
    e.preventDefault();
    startLoading(true);
    startLogin(email.toLowerCase(), password);
  };

  return (
    <>
      <Flex minH={"100vh"} align={"center"} justify={"center"} bg={'#f6f9fb'}>
        <Stack mx={"auto"} w={"md"} maxW={"lg"} py={12} px={6}>
          <Box rounded={"lg"} boxShadow={"lg"} p={8} bg={'white'}>
            <Stack spacing={4}>
              <form onSubmit={handleLogin} className="form__login--box">
                <FormControl mb={3}>
                  <FormLabel>Correo electrónico</FormLabel>
                  <Input
                    autoComplete="off"
                    borderColor="#a9a9a9"
                    className="form__login--input"
                    name="email"
                    onChange={handleInputChange}
                    type="email"
                    value={email}
                    _hover={{
                      borderColor: "#a9a9a9",
                    }}
                    _focus={{ borderColor: "#262626" }}
                  />
                </FormControl>

                <FormControl mb={3}>
                  <FormLabel>Contraseña</FormLabel>
                  <Input
                    borderColor="#a9a9a9"
                    className="form__login--input"
                    name="password"
                    onChange={handleInputChange}
                    type="password"
                    value={password}
                    _hover={{
                      borderColor: "#a9a9a9",
                    }}
                    _focus={{ borderColor: "#262626" }}
                  />
                </FormControl>

                <ButtonWithSpinner
                  titleButton="Iniciar sesión"
                  isLoading={isLoginLoading}
                />
                <Link
                  color="blue.500"
                  fontSize="sm"
                  mt={2}
                  alignSelf="flex-start"
                  onClick={() => {
                    navigate("/auth/check-mail");
                  }}
                >
                  ¿Has olvidado tu contraseña?
                </Link>
              </form>

              <div className="bg__login--img" />
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </>
  );
};

export default LoginScreen;

import React from "react";
import { Flex, Heading, Box, Image, Text, Icon } from "@chakra-ui/react";
import { FaCircleInfo } from "react-icons/fa6";

export const HistogramInfo = ({ image, description }) => {
  return (
    <Flex
      bg="#fff"
      borderRadius="16px"
      borderWidth={1}
      boxShadow="md"
      direction="column"
      justify="space-between"
      p={4}
    >
      <Heading
        alignSelf="flex-start"
        color="#666666"
        fontSize="2xl"
        textAlign="center"
      >
        Histograma
      </Heading>
      <Box justifySelf="center">
        <Image
          alt="histograma"
          objectFit="contain"
          boxSize={"xs"}
          key={image.key}
          mt={4}
          src={image}
          width="100%"
        />
      </Box>

      <Flex align="flex-start" p={2}>
        <Icon as={FaCircleInfo} mt={1} mr={2} />
        <Text alignSelf="flex-start">{description[0].description}</Text>
      </Flex>
    </Flex>
  );
};

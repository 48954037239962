/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Box, Flex, ScaleFade } from "@chakra-ui/react";
import { useAuth } from "../../hooks/useAuth";
import { useStore } from "../../hooks/useStore";
import ProfileSideMenu from "./ProfileSideMenu";
// import ProfileUserAvatar from "./ProfileUserAvatar";
import ProfileUserInfo from "./ProfileUserInfo";
import ProfileUserPassword from "./ProfileUserPassword";
import { RenderLoading } from "../RenderAlert";

export const ProfileAdminContent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSection, setSelectedSection] = useState("profile");

  const { usuario } = useAuth();
  const { getOrganization, organizacion } = useStore();

  const infoRef = useRef(null);
  const passwordRef = useRef(null);
  const profileRef = useRef(null);

  const handleSectionChange = (section) => {
    setSelectedSection(section);
    // Hacer scroll a la sección correspondiente al cambiar entre 'info' y 'password'
    if (section === "info") {
      infoRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "password") {
      passwordRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "profile") {
      profileRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getOrganization(usuario.id_organizacion);
      setIsLoading(false);
      return;
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in>
          <Flex direction={{ base: "column", md: "row" }}>
            {/* Menú lateral */}
            <ProfileSideMenu
              handle={handleSectionChange}
              selectedSection={selectedSection}
            />

            <Box flex="1" ml={4}>
              {/* <Box ref={profileRef}>
                <ProfileUserAvatar
                  usuario={usuario}
                  profesional={organizacion}
                />
              </Box> */}

              <Box ref={infoRef}>
                <ProfileUserInfo user={organizacion} />
              </Box>

              <Box ref={passwordRef}>
                <ProfileUserPassword user={usuario} />
              </Box>
            </Box>
          </Flex>
        </ScaleFade>
      )}
    </>
  );
};

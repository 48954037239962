import React from "react";
import * as ExcelJS from "exceljs";
import { ButtonWithIcon } from "../components/CustomButtons";
import { FaFileExcel } from "react-icons/fa";

export const ExportExamToExcel = ({ data, dataPaciente }) => {
  const handleDownload = async () => {
    // Crear un libro de trabajo
    let dataJsonCPP = [];
    let dataJsonF0 = [];
    let dataJsonH1H2 = [];
    let dataJsonJITTER = [];
    let dataJsonSHIMMER = [];
    let dataJsonSPL = [];

    let arrayPatient = [];
    let arrayFeatures = [];
    arrayPatient.push(dataPaciente);
    arrayFeatures.push(data);

    dataJsonCPP.push(data.resources.CPP.data);
    dataJsonF0.push(data.resources.F0.data);
    dataJsonH1H2.push(data.resources.H1H2.data);
    dataJsonJITTER.push(data.resources.JITTER.data);
    dataJsonSHIMMER.push(data.resources.SHIMMER.data);
    dataJsonSPL.push(data.resources.SPL.data);

    // Se limpian los valores nulos
    const dataCleanedCPP = dataJsonCPP[0].filter(
      (valor) => valor !== null && valor !== undefined
    );
    const dataCleanedF0 = dataJsonF0[0].filter(
      (valor) => valor !== null && valor !== undefined
    );
    const dataCleanedH1H2 = dataJsonH1H2[0].filter(
      (valor) => valor !== null && valor !== undefined
    );
    const dataCleanedJITTER = dataJsonJITTER[0].filter(
      (valor) => valor !== null && valor !== undefined
    );
    const dataCleanedSHIMMER = dataJsonSHIMMER[0].filter(
      (valor) => valor !== null && valor !== undefined
    );
    const dataCleanedSPL = dataJsonSPL[0].filter(
      (valor) => valor !== null && valor !== undefined
    );

    // Se mapean los datos con 3 valores numericos
    const dataArrayCPP = [
      ["CPP"],
      ...dataCleanedCPP.map((cpp) => [Number(cpp).toFixed(1)]),
    ];
    const dataArrayF0 = [
      ["F0"],
      ...dataCleanedF0.map((f0) => [Number(f0).toFixed(0)]),
    ];
    const dataArrayH1H2 = [
      ["H1H2"],
      ...dataCleanedH1H2.map((h1h2) => [Number(h1h2).toFixed(2)]),
    ];
    const dataArrayJITTER = [
      ["JITTER"],
      ...dataCleanedJITTER.map((jitter) => [Number(jitter).toFixed(2)]),
    ];
    const dataArraySHIMMER = [
      ["SHIMMER"],
      ...dataCleanedSHIMMER.map((shimmer) => [Number(shimmer).toFixed(2)]),
    ];
    const dataArraySPL = [
      ["SPL"],
      ...dataCleanedSPL.map((spl) => [Number(spl).toFixed(1)]),
    ];

    const workbook = new ExcelJS.Workbook();

    // Crear hoja datos del paciente
    const sheet1 = workbook.addWorksheet("Datos del paciente");
    sheet1.columns = [
      { header: "Nombre", key: "nombre" },
      { header: "Apellido", key: "apellido" },
      { header: "Email", key: "email" },
      { header: "Rut", key: "rut" },
      { header: "Sexo", key: "sexo" },
      { header: "Telefono", key: "telefono" },
      { header: "Fecha nacimiento", key: "fecha_nacimiento" },
      { header: "Fecha creacion", key: "createdAt" },
    ];
    sheet1.addRows(
      arrayPatient.map((patient) => ({
        nombre: patient.nombre,
        apellido: patient.apellidos,
        email: patient.email,
        rut: patient.rut,
        sexo: patient.sexo,
        telefono: patient.telefono,
        fecha_nacimiento: patient.fecha_nacimiento,
        createdAt: patient.createdAt,
      }))
    );

    // Crear hoja de los features
    const sheet2 = workbook.addWorksheet("Features");
    // sheet2.columns = [{ header: 'F0', key: 'f0' }, { header: 'H1H2', key: 'h1h2' }, { header: 'JITTER', key: 'jitter' }, { header: 'SHIMMER', key: 'shimmer' }, { header: 'SPL', key: 'spl' }];
    for (
      let i = 0;
      i <
      Math.max(
        dataArrayCPP.length,
        dataArrayF0.length,
        dataArrayH1H2.length,
        dataArrayJITTER.length,
        dataArraySHIMMER.length,
        dataArraySPL.length
      );
      i++
    ) {
      const cpp = dataArrayCPP[i] || "";
      const f0 = dataArrayF0[i] || "";
      const h1h2 = dataArrayH1H2[i] || "";
      const jitter = dataArrayJITTER[i] || "";
      const shimmer = dataArraySHIMMER[i] || "";
      const spl = dataArraySPL[i] || "";
      sheet2.addRow([cpp[0], f0[0], h1h2[0], jitter[0], shimmer[0], spl[0]]);
    }
    // sheet2.addRows(arrayFeatures.map(features => ({ f0: features.resources.F0.data, h1h2: features.resources.H1H2.data, jitter: features.resources.JITTER.data, shimmer: features.resources.SHIMMER.data, spl: features.resources.SPL.data })));

    // Crear un blob y descargar el archivo XLSX
    const blob = await workbook.xlsx.writeBuffer();
    const excelBlob = new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Crear un enlace y simular un clic para descargar el archivo
    const link = document.createElement("a");
    link.href = URL.createObjectURL(excelBlob);
    link.download = "datos.xlsx";
    link.click();
  };

  return (
    <>
      <ButtonWithIcon
        titleIcon={FaFileExcel}
        titleButton="Exportar Excel"
        fn={handleDownload}
      >
        Excel
      </ButtonWithIcon>
    </>
  );
};

import React, { useContext } from "react";
import {
  Center,
  CircularProgress,
  CircularProgressLabel,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { FaFileAudio, FaTrash } from "react-icons/fa6";

import { FileUploader } from "../../components/FileUploader";
import { ExamenContext } from "../../context/examen/ExamenContext";
import { ButtonNormal } from "../../components/CustomButtons";

const RenderFilesTable = ({
  uploadedFiles,
  uploadedPercent,
  handleDeleteFile,
}) => {
  if (uploadedFiles.length === 0) return null;

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Nombre archivo</Th>
            <Th>Progreso</Th>
            <Th>Acciones</Th>
          </Tr>
        </Thead>
        <Tbody>
          {uploadedFiles.map((file, index) => (
            <Tr key={index}>
              <Td>{file.name}</Td>
              <Td>
                {
                  <CircularProgress
                    color="green.400"
                    size={"3rem"}
                    value={uploadedPercent[index] || 0}
                  >
                    <CircularProgressLabel>
                      {uploadedPercent[index] || 0}%
                    </CircularProgressLabel>
                  </CircularProgress>
                }
              </Td>
              <Td>
                <Tooltip hasArrow label="Eliminar archivo">
                  <IconButton
                    onClick={() => handleDeleteFile(index)}
                    size="lg"
                    variant="ghost"
                    aria-label="eliminar archivo"
                    icon={<FaTrash />}
                  />
                </Tooltip>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export const AvmScreen = ({handleCreate}) => {
  const { uploadedFiles, uploadedPercent, setUploadedFilesList,
    setUploadedPercent,} = useContext(ExamenContext);

    const handleDeleteFile = (index) => {
      // Elimina el archivo del estado uploadedFiles
      const newUploadedFiles = [...uploadedFiles];
      newUploadedFiles.splice(index, 1);
      setUploadedFilesList(newUploadedFiles);
    
      // También debes eliminar el progreso del archivo eliminado si lo deseas
      const newUploadedPercent = { ...uploadedPercent };
      delete newUploadedPercent[index];
      setUploadedPercent(newUploadedPercent);
    };
  return (
    <>
      Tipo de examen: Clínica
      <Center mt={6}>
        <FileUploader
          placeHolder={"Seleccionar archivos (.wav, .txt)"}
          accept={".wav, .txt"}
          icon={FaFileAudio}
        />
      </Center>
      {uploadedFiles.length >= 1 && (
        <>
          <Text as={"h2"} fontWeight={"bold"}>
            Archivos seleccionados: {uploadedFiles.length}
          </Text>
          <RenderFilesTable
            uploadedFiles={uploadedFiles}
            uploadedPercent={uploadedPercent}
            setUploadedFilesList={setUploadedFilesList}
            setUploadedPercent={setUploadedPercent}
            handleDeleteFile={handleDeleteFile}
          />

          <ButtonNormal fn={handleCreate} titleButton="Finalizar examen" />
        </>
      )}
    </>
  );
};

import {    
  StyleSheet,    
} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,      
  },
  margen: {      
    marginBottom: 10,      
  },
  margenGraphic: {
    marginBottom: 16,      
  },
  margenGraphicVideo: {
    marginBottom: 4,      
  },
  margenGraphicVideoAG: {
    marginBottom: 1,      
  },
  margenGraphicAG: {
    marginBottom: 25,      
  },
  image: {
    textAlign: "left",
    width: "50px",
    height: "20px",          
  },
  imageMaker: {
    textAlign: "left",
    width: "50px",
    height: "20px",          
  },
  graphicImage: {    
    width: "400",
    height: "320px",          
  },
  svg: {
    height: "10",
    width: "525",
  },
  line: {
    x1: "0",
    y1: "5",
    x2: "680",
    y2: "5",
    strokeWidth: "1",
    stroke: "rgb(0,0,0)",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  titleBordePage: {
    width: "100%",
    textAlign: "right",
    fontSize: 10,
    fontWeight: "bolder",
    fontFamily: "Helvetica-Bold",
    color: "#4a5568",          
  },
  titleBordePageCenter: {    
    color: "#4a5568",          
    width: "100%",
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bolder",
    fontFamily: "Helvetica-Bold",
  },
  oneTitle: {
    width: "90%",
    textAlign: "left",
    color: "rgb(31, 105, 241)",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
  },
  oneTitleGraphic: {
    width: "90%",
    textAlign: "left",
    color: "rgb(31, 105, 241)",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    marginLeft: "5px",      
    marginTop: "5px",
  },
  oneTitleGraphicVideo: {
    width: "90%",
    textAlign: "left",
    color: "rgb(31, 105, 241)",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    marginLeft: "5px",          
  },
  oneSubTitle: {
    width: "90%",
    textAlign: "left",      
    fontSize: 10,
    color: "rgb(31, 105, 241)",
  },
  oneSubTitleCell: {
    width: "90%",
    textAlign: "center",      
    fontSize: 10,
    fontFamily: "Helvetica-Bold",      
    color: "#4a5568",
  },
  titleNote: {
    width: "90%",
    textAlign: "left",      
    fontSize: 10,
    fontFamily: "Helvetica-Bold",      
    color: "#4a5568",
    padding: "17px",
  },
  oneSubTitleGraphic: {
    width: "90%",
    textAlign: "left",      
    fontSize: 8,
    color: "rgb(31, 105, 241)",
    marginLeft: "5px",      
    marginTop: "4px",
  },
  oneSubTitleGraphicVideo: {
    width: "90%",
    textAlign: "left",      
    fontSize: 10,
    color: "rgb(31, 105, 241)",
    marginLeft: "5px",          
  },
  titlEsignature: {
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },
  text: {
    margin: 12,
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Helvetica",
  },
  graphic: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  textTitle: {
    color: "rgb(31, 105, 241)",      
    fontSize: 12,
    margin: 12,
    textAlign: "justify",
    fontFamily: "Helvetica",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderColor: "rgb(31, 105, 241)",  
  },
  tableWhite: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",  
    borderColor: "rgb(31, 105, 241)",  
  },
  tableRowParams: {
    // margin: "auto",
    width: "120%",
    flexDirection: "row",  
    borderColor: "rgb(31, 105, 241)",
    borderBottomWidth: 1  
  },
  tableWhiteRow: {      
    flexDirection: "row",
    marginBottom: 20,            
  },
  tableCol100AG: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)",
    backgroundColor: "rgb(31, 105, 241)",      
  },
  tableCol100: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)",
    backgroundColor: "white",      
  },
  tableColWhite: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol20AG: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)",       
  },
  tableCol30AG: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)",       
  },  
  tableCol50AG: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)",       
  },
  tableCol70AG: {
    width: "70%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)",       
  },
  tableCol75AG: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)",       
  },
  tableCol50: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)", 
    padding: "8px",     
  },
  tableCol70: {
    width: "70%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)", 
    padding: "8px",     
  },
  tableCol15: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)", 
    padding: "8px",     
  },
  tableCol30: {
    width: "33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },  
  tableCol20: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColAG: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)",      
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)",
    padding: "6px",
  },
  tableCol3: {
    width: "33.3333333%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)",
    padding: "6px",
  },
  tableCol4: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)",
    padding: "6px",
  },
  tableCol12: {
    width: "16.6666667%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "rgb(31, 105, 241)",
    padding: "6px",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    marginLeft: "5px",
    fontSize: 10,
    borderColor: "rgb(31, 105, 241)",
    color: "rgb(31, 105, 241)",
    padding: "3px",
  },
  tableCellBold: {
    width: '100%',
    margin: "auto",
    marginLeft: "5px",
    marginTop: 5,
    fontSize: 10,
    borderColor: "rgb(31, 105, 241)",
    color: "rgb(31, 105, 241)",
    fontFamily: "Helvetica-Bold",
    padding: "3px",
  },
  tableCellTitle: {
    textAlign: "left",      
    marginLeft: "5px",
    marginTop: 5,
    fontSize: 10,
    color: "white", 
    fontFamily: "Helvetica-Bold",     
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },


  tableFeatures: {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: "rgb(31, 105, 241)",
    marginBottom: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0, 
  },
  row: {
    flexDirection: 'row',
  },
  cell: {
    flex: 1,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0, 
    borderTopWidth: 0, 
    borderBottomWidth: 0, 
    borderStyle: 'solid',
    borderColor: "rgb(31, 105, 241)",
    padding: 0,
  },  
  cell2: {
    flex: 1,
    borderWidth: 1,
    borderLeftWidth: 0,     
    borderTopWidth: 0,     
    borderStyle: 'solid',
    borderColor: "rgb(31, 105, 241)",
    padding: "6px",
  },  
  cell3: {
    flex: 1,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0, 
    borderTopWidth: 0,
    borderBottomWidth: 0,  
    borderStyle: 'solid',
    borderColor: "rgb(31, 105, 241)",
    padding: 0,
  },
  cell4: {
    flex: 1,
    borderWidth: 1,
    borderLeftWidth: 0,    
    borderTopWidth: 0, 
    borderStyle: 'solid',
    borderColor: "rgb(31, 105, 241)",
    padding: "6px",
  },
  cell5: {
    flex: 1,
    borderWidth: 1,
    borderLeftWidth: 0,    
    borderTopWidth: 0,
    borderRightWidth: 1, 
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: "rgb(31, 105, 241)",
    padding: "6px",
  },  
});
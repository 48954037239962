import React, { useContext, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FaUserPlus } from "react-icons/fa";
import { StoreContext } from "../../context/store/StoreContext";
import { CuentaForm } from "../Forms/CuentaForm";
import { formatearRut } from "../../utils/formatearRut";
import { ButtonWithIcon } from "../CustomButtons";

const initialValues = {
  nombre: "",
  rut: "",
  id_tipoOrganizacion: "",
  email: "",
  password: "",
  telefono: "",
  direccion: "",
  fecha_pago: "",
};

const initialOptions = {
  abma: false,
  avm: false,
  video: false,
  otro: false,
};

export const CrearCuenta = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createOrganization } = useContext(StoreContext);

  const [isHabilitado, setIsHabilitado] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rut, setRut] = useState("");
  const [options, setOptions] = useState(initialOptions);

  const handleRutChange = (event) => {
    const formattedRut = formatearRut(event.target.value);
    setRut(formattedRut);
  };

  const handleCheckboxChange = (option) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  };

  const handleCreate = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const dataCuenta = {
      ...values,
      rut: rut,
      password: rut.split("-")[0].replace(/\./g, ""),
      passwordForEmail: rut.split("-")[0].replace(/\./g, ""),
      options,
      is_habilitado: isHabilitado,
    };
    await createOrganization(dataCuenta);
    setSubmitting(false);
    setIsLoading(false);
    setIsHabilitado(false);
    setRut("");
    setOptions(initialOptions);
    onClose();
  };

  return (
    <>
      <ButtonWithIcon
        fn={onOpen}
        titleIcon={FaUserPlus}
        titleButton="Crear Cuenta"
      />

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nueva organización</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CuentaForm
              handleRutChange={handleRutChange}
              initialValues={initialValues}
              isLoading={isLoading}
              onSubmit={handleCreate}
              habilitado={isHabilitado}
              setHabilitado={setIsHabilitado}
              rut={rut}
              setOptions={handleCheckboxChange}
              options={options}
              stage="crear"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

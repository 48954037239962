export const CREAR_NUEVO_PACIENTE = "CREAR_NUEVO_PACIENTE";
export const CARGAR_PACIENTE = "CARGAR_PACIENTE";
export const CARGAR_PACIENTES = "CARGAR_PACIENTES";

export const CARGAR_TIPO_ORGANIZACIONES = "CARGAR_TIPO_ORGANIZACIONES";
export const SET_ORGANIZACION = "SET_ORGANIZACION";

export const CARGAR_CUENTAS = "CARGAR_CUENTAS";

export const CARGA_PROFESIONALES = "CARGA_PROFESIONALES";
export const SET_PROFESIONAL = "SET_PROFESIONAL";

export const CARGA_FEEDBACKS = "CARGA_FEEDBACKS";
export const GET_FEEDBACK = "GET_FEEDBACK";

export const GET_USER_PHOTO = "GET_USER_PHOTO";

export const GET_SUMMARIES_DATA = 'GET_SUMMARIES_DATA';
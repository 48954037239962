import React, { useContext, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FaUserPlus } from "react-icons/fa";
import { PacienteForm } from "../Forms/PacienteForm";
import { StoreContext } from "../../context/store/StoreContext";
import { formatearRut } from "../../utils/formatearRut";
import { ButtonWithIcon } from "../CustomButtons";

const initialValues = {
  rut: "",
  nombre: "",
  apellidos: "",
  email: "",
  telefono: "",
  fecha_nacimiento: "",
  sexo: "",
  prevision: "",
  direccion: "",
  estado_civil: "",
};

export const CrearPaciente = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createPatient } = useContext(StoreContext);

  const [rut, setRut] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCrearPaciente = (values, { setSubmitting }) => {
    setIsLoading(true);

    const dataFormulario = { ...values, rut: rut };
    createPatient(dataFormulario);
    setSubmitting(false);
    setIsLoading(false);
    onClose();
    setRut("");
  };

  const handleRutChange = (event) => {
    const formattedRut = formatearRut(event.target.value);
    setRut(formattedRut);
  };

  return (
    <>
      <ButtonWithIcon
        fn={onOpen}
        titleIcon={FaUserPlus}
        titleButton="Crear Paciente"
      />

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nuevo Pacient</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PacienteForm
              handleRutChange={handleRutChange}
              initialValues={initialValues}
              isLoading={isLoading}
              onSubmit={handleCrearPaciente}
              rut={rut}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

import { useEffect, useState } from "react";
import { fetchAllExamenes } from "../helpers/fetchAllExamenes";

export const useExamenes = () => {
  const [isCargando, setIsCargando] = useState(true);
  const [examenes, setExamenes] = useState([]);

  useEffect(() => {
    //carga de los examenes
    fetchAllExamenes().then((examenes) => {
      setIsCargando(false);
      setExamenes(examenes);
    });
  }, []);

  return {
    isCargando,
    examenes,
  };
};

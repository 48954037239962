/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from "react";
import {
  Button,
  Icon,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import { useNavigate, useParams } from "react-router-dom";
import { lanekApi } from "../api/lanekApi";
import { ExamOptionCard } from "../components/ExamOptionCard";
// import { ExamenContext } from "../context/examen/ExamenContext";
// import { RenderLoading } from "../components/RenderAlert";
import {
  FaRunning,
  FaMicrophoneAlt,
  FaPhotoVideo,
  FaEllipsisH,
} from "react-icons/fa";
// import AuthContext from "../context/auth/AuthContext";
import Cookies from "js-cookie";

const productos = [
  {
    id: "1",
    nombre: "abma",
    description:
      "Puedes subir videos de evaluaciones de análisis del movimiento utilizando sistema ABMA.",
    icono: FaRunning,
    is_habilitado: true,
  },
  {
    id: "2",
    nombre: "avm",
    description:
      "Puedes subir archivos de audio obtenidos del dispositivo AVM.",
    icono: FaMicrophoneAlt,
    is_habilitado: true,
  },
  {
    id: "3",
    nombre: "video",
    description:
      "Puedes subir video para análisis de biomarcadores digitales de imágenes y audio.",
    icono: FaPhotoVideo,
    is_habilitado: true,
  },
  {
    id: "4",
    nombre: "otro",
    description: "Puedes subir otros archivos o exámenes para ser procesados.",
    icono: FaEllipsisH,
    is_habilitado: true,
  },
];

const ExamsOptionsScreen = () => {
  const navigate = useNavigate();
  const { idpaciente } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const { exams } = useContext(AuthContext);

  let examOptions;
  if (Cookies.get("userData")) {
    examOptions = JSON.parse(Cookies.get("userData"));
  }

  const [productoSeleccionado, setProductoSeleccionado] = useState("");

  const cancelRef = useRef();

  const handleSelectedExam = (e) => {
    setProductoSeleccionado(e.target.value);
    onOpen();
  };

  const handleNuevoExamen = async () => {
    let idexamen;
    await lanekApi
      .post("/examen")
      .then(({ data }) => (idexamen = data.item.id));
    navigate(
      `/paciente/${idpaciente}/nuevo_examen/${productoSeleccionado}/${idexamen}`,
      { replace: true }
    );
    onClose();
  };

  return (
    <>
      <Box maxW="7xl" mx={"auto"}>
        <SimpleGrid columns={{ base: 2, lg: 3 }} spacing={{ base: 5, lg: 8 }}>
          {productos.map(
            ({ nombre, description, is_habilitado, id, icono }) => (
              <ExamOptionCard
                key={id}
                heading={nombre}
                icon={<Icon as={icono} w={"2.5rem"} h={"2.5rem"} />}
                description={description}
                disable={!is_habilitado}
                value={nombre}
                handle={handleSelectedExam}
                options={examOptions.exams}
              />
            )
          )}
        </SimpleGrid>
      </Box>

      {productoSeleccionado && (
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogBody>
                Estás seguro de continuar? No podrás volver atrás.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancelar
                </Button>
                <Button colorScheme="green" onClick={handleNuevoExamen} ml={3}>
                  Confirmar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};

export default ExamsOptionsScreen;

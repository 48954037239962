import React from 'react';
import { Box } from "@chakra-ui/react";

const PanelContent = ({children}) => {
  return (
    <Box>
      {children}
    </Box>
  );
}

export default PanelContent;

import { Box } from "@chakra-ui/react";
import React from "react";
import { BlobProvider } from "@react-pdf/renderer";
import InfoData from "../../jsonData/InfoData";
import PdfDocumentAVM from "../generateReport/avm/general";
// import PdfDocumentMaker from "../generateReport/maker/general";
import { ButtonWithIcon } from "../CustomButtons";
import { FaFilePdf } from "react-icons/fa";

export const DetallePdf = ({ images, data, dataPaciente, dataExamType }) => {
  return (
    <>
      <Box mt={6}>
        <Box mt={4}>
          <BlobProvider
            document={
              <PdfDocumentAVM
                data={data}
                images={images}
                infodata={InfoData}
                paciente={dataPaciente}
              />
            }
          >
            {({ url }) => (
              <ButtonWithIcon
                fn={() => window.open(url, "_blank")}
                titleIcon={FaFilePdf}
                titleButton={"Exportar PDF"}
              />
            )}
          </BlobProvider>
        </Box>
      </Box>
    </>
  );
};

import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { lanekApi } from "../../api/lanekApi";

const ProfileUserPassword = ({ user }) => {
  const [showPassword, setShowPassword] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const formValidationSchema = Yup.object().shape({
    password: Yup.string().required("Este campo es requerido"),
    confirmedPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir")
      .required("Este campo es requerido"),
  });

  const formInitialValues = {
    password: "",
    confirmedPassword: "",
  };

  const onUpdate = async (values, { setSubmitting, resetForm}) => {
    onClose();

    try {
      console.log(values);
      await lanekApi.put(`/usuario/cambiar-password/${user.id_usuario}`, {
        password: values.password,
      });
      toast({
        description: "La contraseña se ha actualizado.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error al cambiar la contraseña:", error);
      toast({
        description: "Hubo un error al cambiar la contraseña.",
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
      resetForm(formInitialValues)
    }
  };

  return (
    <>
      {user && (
        <Flex
          direction={"column"}
          bg={"#fff"}
          color={"#333"}
          align="center"
          borderRadius={"16px"}
          boxShadow="md"
          mb={8}
          padding={7}
        >
          <Heading w="100%" fontWeight={"bold"} size={"md"} mb={4}>
            Cambiar contraseña
          </Heading>

          <Box w={"full"}>
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={onUpdate}
            >
              {({ isSubmitting, dirty, resetForm, handleSubmit }) => (
                <Form>
                  <FormControl id="password" isRequired mb={2}>
                    <FormLabel>Nueva contraseña</FormLabel>
                    <InputGroup>
                      <Field name="password">
                        {({ field }) => (
                          <>
                            <Input
                              {...field}
                              autoComplete="off"
                              type={showPassword ? "text" : "password"}
                            />
                            <InputRightElement h={"full"}>
                              <Button
                                variant={"ghost"}
                                size={"sm"}
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                              </Button>
                            </InputRightElement>
                          </>
                        )}
                      </Field>
                    </InputGroup>
                    <ErrorMessage name="password" />
                  </FormControl>

                  <FormControl id="confirmedPassword" isRequired>
                    <FormLabel>Confirmar contraseña</FormLabel>
                    <InputGroup>
                      <Field name="confirmedPassword">
                        {({ field }) => (
                          <>
                            <Input
                              {...field}
                              autoComplete="off"
                              type={showPassword ? "text" : "password"}
                            />
                            <InputRightElement h={"full"}>
                              <Button
                                size={"sm"}
                                variant={"ghost"}
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                              </Button>
                            </InputRightElement>
                          </>
                        )}
                      </Field>
                    </InputGroup>
                    <ErrorMessage name="confirmedPassword" />
                  </FormControl>

                  <Flex justify={"flex-end"} mt={4}>
                    <Button
                      colorScheme="twitter"
                      isLoading={isSubmitting}
                      mr={4}
                      isDisabled={!dirty}
                      onClick={onOpen}
                    >
                      Cambiar contraseña
                    </Button>

                    <Button
                      colorScheme="gray"
                      onClick={() => {
                        resetForm();
                      }}
                      isDisabled={!dirty}
                    >
                      Cancelar
                    </Button>
                  </Flex>

                  <Modal
                    isCentered
                    isOpen={isOpen}
                    onClose={onClose}
                    motionPreset="slideInBottom"
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Confirmación</ModalHeader>
                      <ModalBody>
                        ¿Estas seguro de cambiar tu contraseña?
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          colorScheme="twitter"
                          mr={3}
                          onClick={() => {
                            onClose();
                            handleSubmit();
                          }}
                        >
                          Confirmar
                        </Button>
                        <Button variant="ghost" onClick={onClose}>
                          Cancelar
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Form>
              )}
            </Formik>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default ProfileUserPassword;
